/* eslint-disable import/no-cycle */
import { Add, Edit } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Chip,
  Grid,
  IconButton,
  Step,
  StepLabel,
  Stepper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { ChipData, SchedulerTime, SchedulerType } from "../../@types/allTypes";
import SelectDays from "../../Atoms/SchedulerDays/SchedulerDays";
import SelectIntensity from "../../Atoms/SchedulerIntensity/SchedulerIntensity";
import SelectTime from "../../Atoms/SchedulerTime/SchedulerTime";
import ColorlibStepIcon from "../../Molecules/ColorlibStepIcon/ColorlibStepIcon";
import CustomButton from "../../Molecules/CustomButton/CustomButton";
import { ChipContext } from "../../Pages/Details/Details";

const Scheduler2: React.FC<SchedulerType> = ({
  scheduleList,
  isLoading,
  setIsLoading,
  minIntensity,
  maxIntensity,
  setShowDetails,
  roomSize,
  noSelected,
}) => {
  const { t } = useTranslation();
  const currentSchedule = useContext(ChipContext);

  const [maxChips, setMaxChips] = useState<boolean>(false);
  const [chipData, setChipData] = useState<ChipData[]>(currentSchedule || []);

  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  const [isEditing, setIsEditing] = useState<{
    used: boolean;
    index: number | null;
  }>({
    used: false,
    index: null,
  });

  const [activeStep, setActiveStep] = React.useState(0);
  const [completed, setCompleted] = React.useState<{
    [k: number]: boolean;
  }>({});

  const [currentTimes, setCurrentTimes] = useState<SchedulerTime[]>([]);
  const [currentType, setCurrentType] = useState<"expert" | "auto">("auto");

  const [currentInterval, setCurrentInterval] = useState<number>(50);
  const [currentIntensity, setCurrentIntensity] = useState<number>(0);
  const [currentDays, setCurrentDays] = useState<number[]>([
    0, 1, 2, 3, 4, 5, 6,
  ]);

  const [days, setDays] = useState([
    {
      day: t("common.weekdays.1"),
      checked: currentDays.includes(0),
    },
    {
      day: t("common.weekdays.2"),
      checked: currentDays.includes(1),
    },
    {
      day: t("common.weekdays.3"),
      checked: currentDays.includes(2),
    },
    {
      day: t("common.weekdays.4"),
      checked: currentDays.includes(3),
    },
    {
      day: t("common.weekdays.5"),
      checked: currentDays.includes(4),
    },
    {
      day: t("common.weekdays.6"),
      checked: currentDays.includes(5),
    },
    {
      day: t("common.weekdays.7"),
      checked: currentDays.includes(6),
    },
  ]);

  const indexToDays = (selectedDays: number[]) => {
    const weekdays: string[] = [];
    selectedDays.forEach((element) => {
      weekdays.push(days[element].day);
    });
    return weekdays;
  };
  const steps = [
    {
      title: t("scheduler.secondStep"),
      description: SelectDays(
        currentDays,
        chipData,
        setCurrentDays,
        days,
        setDays
      ),
    },
    {
      title: t("scheduler.thirdStep"),
      description: SelectTime(currentTimes, setCurrentTimes),
    },
    {
      title: t("scheduler.fifthStep"),
      description: SelectIntensity(
        minIntensity,
        maxIntensity,
        currentIntensity,
        setCurrentIntensity,
        currentInterval,
        setCurrentInterval,
        currentType,
        setCurrentType
      ),
    },
  ];

  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? // It's the last step, but not all steps have been completed,
          // find the first step that has been completed
          steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
    setActiveStep(newActiveStep);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step: number) => () => {
    setActiveStep(step);
  };

  /* < als 10m2 /Fan Intensity  bei 1 20% 2 30% 3 40% 4 50%
  10m2 - 20m2 /Fan Intensity  bei 1 30% 2 40% 3 50% 4 60%
  20m2-30m2 /Fan Intensity  bei 1 40% 2 50% 3 60% 4 70%
  30m2-40m2 /Fan Intensity  bei 1 50% 2 60% 3 70% 4 80%
  > 40m2 /Fan Intensity  bei 1 60% 2 70% 3 80% 4 100% */
  const calcIntensity = (interval: number, size = 0) => {
    const option = Math.floor(interval / 20);
    return size > 40 ? 4 + option + 2 : Math.floor(size / 10) + option + 1;
  };

  const createNewChip = () => {
    const intensity =
      currentType === "auto"
        ? calcIntensity(currentInterval, roomSize) * 10
        : currentIntensity;
    return {
      type: currentType,
      intensity,
      interval: currentInterval,
      times: currentTimes,
      days: currentDays,
    };
  };

  const updateChipData = (oldChipData: ChipData[], newChip: ChipData) => {
    let updatedChipData = oldChipData;
    if (!isEditing.used || (isEditing.used && isEditing.index !== null)) {
      updatedChipData = oldChipData
        .map((chip) => {
          const newChipData = chip;
          newChipData.days = chip.days.filter(
            (day) => !currentDays.includes(day)
          );
          return newChipData;
        })
        .filter((chip) => chip.days.length > 0);
    }
    updatedChipData.push(newChip);
    return updatedChipData;
  };

  const resetState = () => {
    setCurrentDays([0, 1, 2, 3, 4, 5, 6]);
    setDays([
      {
        day: t("common.weekdays.1"),
        checked: true,
      },
      {
        day: t("common.weekdays.2"),
        checked: true,
      },
      {
        day: t("common.weekdays.3"),
        checked: true,
      },
      {
        day: t("common.weekdays.4"),
        checked: true,
      },
      {
        day: t("common.weekdays.5"),
        checked: true,
      },
      {
        day: t("common.weekdays.6"),
        checked: true,
      },
      {
        day: t("common.weekdays.7"),
        checked: true,
      },
    ]);
    setIsExpanded(false);
    setIsEditing({ used: false, index: null });
    setCurrentType("auto");
    setCurrentTimes([]);
    setCurrentIntensity(0);
    setCurrentInterval(50);
    setActiveStep(0);
  };

  const handleComplete = () => {
    if (currentTimes.length < 1) {
      toast.error(t("scheduler.minOne"));
      return;
    }
    if (maxChips) {
      toast.error("Reached maximum schedules allowed");
      return;
    }
    const newChip = createNewChip();
    const currentChips = updateChipData(chipData, newChip);
    setChipData([...currentChips]);
    const totalChips = currentChips.length;
    if (totalChips >= 42) setMaxChips(true);
    resetState();
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const handleDelete = (index: number) => {
    const newCurrentSchedule = chipData;
    newCurrentSchedule.splice(index, 1);
    setChipData([...newCurrentSchedule]);
    setMaxChips(false);
  };

  const handleEdit = (index: number) => {
    const currentEditChip = chipData[index];
    setCurrentDays(currentEditChip.days);
    setDays([
      {
        day: t("common.weekdays.1"),
        checked: currentEditChip.days.includes(0),
      },
      {
        day: t("common.weekdays.2"),
        checked: currentEditChip.days.includes(1),
      },
      {
        day: t("common.weekdays.3"),
        checked: currentEditChip.days.includes(2),
      },
      {
        day: t("common.weekdays.4"),
        checked: currentEditChip.days.includes(3),
      },
      {
        day: t("common.weekdays.5"),
        checked: currentEditChip.days.includes(4),
      },
      {
        day: t("common.weekdays.6"),
        checked: currentEditChip.days.includes(5),
      },
      {
        day: t("common.weekdays.7"),
        checked: currentEditChip.days.includes(6),
      },
    ]);
    setCurrentType(currentEditChip.type);
    setCurrentTimes(currentEditChip.times);
    setCurrentIntensity(currentEditChip.intensity);
    setCurrentInterval(currentEditChip.interval);
    setIsExpanded(true);
    setActiveStep(0);
    setIsEditing({ used: true, index });
  };

  // Save all the times and go back to the device list
  const saveAndLeave = (type: string) => {
    setIsLoading(true);
    if (type === "save") {
      scheduleList(chipData);
    } else {
      setIsLoading(false);
      setShowDetails(false);
      // history("/")
    }
  };

  return (
    <Grid container className="scheduler2">
      <Grid item xs={12}>
        <TableContainer>
          <Table aria-label="simple table" padding="checkbox" size="small">
            <TableBody>
              {chipData.map((chip: ChipData, index) => {
                return (
                  <TableRow key={chip.days[0]} style={{ height: 53 }}>
                    <TableCell align="left">
                      <Chip
                        size="small"
                        label={`${indexToDays(chip.days)}`}
                        onDelete={() => handleDelete(index)}
                      />
                      <IconButton
                        color="primary"
                        onClick={() => handleEdit(index)}
                      >
                        <Edit />
                      </IconButton>
                      {chip.times
                        .sort((time1, time2) => {
                          if (time1.timeFrom < time2.timeFrom) {
                            return -1;
                          }
                          if (time1.timeFrom > time2.timeFrom) {
                            return 1;
                          }
                          // a must be equal to b
                          return 0;
                        })
                        .map((time) => {
                          return (
                            <Typography
                              component="span"
                              style={{ fontSize: "0.8rem", fontWeight: "bold" }}
                            >
                              {` ${time.timeFrom}-${time.timeTo} `}
                            </Typography>
                          );
                        })}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <Accordion expanded={isExpanded}>
          <AccordionSummary
            onClick={() => setIsExpanded(!isExpanded)}
            expandIcon={<Add />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>{t("deviceDetails.newSchedule")}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Stepper nonLinear activeStep={activeStep} variant="outlined">
              {steps.map((step, index) => (
                <Step key={step.title} completed={completed[index]}>
                  <StepLabel
                    StepIconComponent={ColorlibStepIcon}
                    onClick={handleStep(index)}
                  >
                    {step.title}
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
            {allStepsCompleted() ? (
              <>
                <Typography component="div" sx={{ mt: 2, mb: 1 }}>
                  All steps completed - you&apos;re finished
                </Typography>
                <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                  <Box sx={{ flex: "1 1 auto" }} />
                  <Button onClick={handleReset}>Reset</Button>
                </Box>
              </>
            ) : (
              <>
                <Box sx={{ mt: 2, mb: 1 }}>{steps[activeStep].description}</Box>
                <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                  <Button
                    color="inherit"
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    sx={{ mr: 1, fontWeight: "bold" }}
                  >
                    {t("common.back")}
                  </Button>
                  <Box sx={{ flex: "1 1 auto" }} />
                  {activeStep < steps.length - 1 ? (
                    <Button
                      onClick={handleNext}
                      sx={{ mr: 1, fontWeight: "bold" }}
                    >
                      {t("common.next")}
                    </Button>
                  ) : (
                    <Button
                      onClick={handleComplete}
                      sx={{ fontWeight: "bold" }}
                    >
                      {t("scheduler.addTime")}
                    </Button>
                  )}
                </Box>
              </>
            )}
          </AccordionDetails>
        </Accordion>
        <Grid
          className="scheduler2Buttons"
          item
          container
          xs={12}
          spacing={2}
          justifyContent="flex-end"
        >
          <Grid item>
            <CustomButton
              color="primary"
              disabled={isLoading}
              variant="contained"
              onClick={() => {
                saveAndLeave("save");
              }}
            >
              {`${t("common.save")} ${t("common.settings")}`}
            </CustomButton>
          </Grid>
          <Grid item>
            <CustomButton
              color="primary"
              disabled={isLoading && !noSelected}
              variant="contained"
              onClick={() => {
                saveAndLeave("back");
              }}
            >
              {t("common.back")}
            </CustomButton>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Scheduler2;
