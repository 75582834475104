// import { useTranslation } from 'react-i18next';
import dayjs from "dayjs";
import tz from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import { useTranslation } from "react-i18next";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Footer from "../Organisms/Footer/Footer";
import Header from "../Organisms/Header/Header";
import PrivateRoute from "../Organisms/PrivateRoute/PrivateRoute";
import "../Styles/styles.scss";
import About from "./About/About";
import Contact from "./Contact/Contact";
import DeviceList from "./DeviceList/DeviceList";
import Faq from "./Faq/Faq";
import Help from "./Help/Help";
import Login from "./Login/Login";

export const App = () => {
  const { t } = useTranslation();
  dayjs.extend(utc);
  dayjs.extend(tz);
  dayjs.tz.setDefault(dayjs.tz.guess());
  const linkList = [
    {
      url: "/help",
      text: t("common.helpPage"),
      hide: ["help", "login"],
    },
    {
      url: "/about",
      text: t("common.aboutPage"),
      hide: ["about"],
    },
    {
      url: "https://revoltab.com",
      text: t("common.shopPage"),
      hide: [""],
    },
    {
      url: "/contact",
      text: t("common.contactPage"),
      hide: ["contact"],
    },
    {
      url: "/faq",
      text: t("common.faqPage"),
      hide: ["faq", "login"],
    },
  ];

  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route
            path="/login"
            element={
              <>
                <Header />
                <Login />
                <Footer links={linkList} place="login" />
              </>
            }
          />
          <Route
            path="/"
            element={
              <PrivateRoute>
                <>
                  <Header />
                  <DeviceList />
                  <Footer links={linkList} place="/" />
                </>
              </PrivateRoute>
            }
          />
          <Route
            path="/contact"
            element={
              <>
                <Header />
                <Contact />
                <Footer links={linkList} place="contact" />
              </>
            }
          />
          <Route
            path="/about"
            element={
              <>
                <Header />
                <About />
                <Footer links={linkList} place="about" />
              </>
            }
          />
          {/* <Route path="/devices/details" element={<PrivateRoute />}>
            <Route path="/devices/details" element={
              <React.Fragment>
                <Header />
                <Details />
                <Footer links={linkList} place="details" />
              </React.Fragment>
            } />
          </Route> */}
          <Route
            path="/help"
            element={
              <PrivateRoute>
                <>
                  <Header />
                  <Help />
                  <Footer links={linkList} place="help" />
                </>
              </PrivateRoute>
            }
          />
          <Route
            path="/faq"
            element={
              <PrivateRoute>
                <>
                  <Header />
                  <Faq />
                  <Footer links={linkList} place="faq" />
                </>
              </PrivateRoute>
            }
          />
        </Routes>
      </BrowserRouter>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover={false}
      />
    </>
  );
};
