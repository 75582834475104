import { Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useStateIfMounted } from "use-state-if-mounted";
import { DeviceListProps, ListDevice } from "../../@types/allTypes";
import {
  getApiDevices,
  startStopEjectDevice,
} from "../../config/services/funcs";
import List from "../../Organisms/List/List";

const DeviceList = () => {
  const [devices, setDevices] = useStateIfMounted<Array<DeviceListProps>>([]);
  const [update, setUpdate] = useState(true);
  const [loading, setLoading] = useState(true);
  const [showDetails, setShowDetails] = useState(false);
  const { t } = useTranslation();

  // Start or Stop the device or device list
  const startFunction = async (listDevices: ListDevice[], action: number) => {
    const funcResponse = await startStopEjectDevice(listDevices, action);
    if (funcResponse.status === 200) {
      toast.success(funcResponse.data);
      setUpdate(!update);
    } else {
      toast.error(funcResponse.data);
    }
  };

  const apiAsyncFunc = async () => {
    setLoading(true);
    await getApiDevices();
    setDevices(JSON.parse(window.localStorage.getItem("devices") || "[]"));
    setLoading(false);
  };

  useEffect(() => {
    setDevices(JSON.parse(window.localStorage.getItem("devices") || "[]"));
    apiAsyncFunc().catch(console.error);
    const intervalId = setInterval(() => {
      apiAsyncFunc().catch(console.error);
    }, 20000);
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  // //Update device info
  // useEffect(() => {

  // }, [update])

  // Set table cells headers
  const headCells = [
    {
      id: "name",
      numeric: false,
      disablePadding: true,
      label: t("devices.tableHead1"),
      sortable: true,
    },
    // {
    //   id: "device-id",
    //   numeric: false,
    //   disablePadding: true,
    //   label: t("devices.tableHead8"),
    //   sortable: true,
    // },
    {
      id: "scent",
      numeric: false,
      disablePadding: false,
      label: t("devices.tableHead2"),
      sortable: true,
    },
    {
      id: "status",
      numeric: true,
      disablePadding: false,
      label: t("devices.tableHead3"),
      sortable: true,
    },
    {
      id: "intensity",
      numeric: true,
      disablePadding: false,
      label: t("devices.tableHead4"),
      sortable: true,
    },
    // {
    //   id: "debug",
    //   numeric: false,
    //   disablePadding: false,
    //   label: t("devices.tableHeadDebug"),
    //   sortable: false,
    // },
    {
      id: "remain",
      numeric: true,
      disablePadding: false,
      label: t("devices.tableHead5"),
      sortable: true,
    },
    {
      id: "subscription",
      numeric: false,
      disablePadding: false,
      label: t("devices.tableHeadSub"),
      sortable: false,
    },
    {
      id: "detail",
      numeric: false,
      disablePadding: false,
      label: t("devices.tableHead6"),
      sortable: false,
    },
  ];

  return (
    <Grid container className="deviceList" justifyContent="center">
      {!showDetails && (
        <Grid item xs={12} className="listTitle">
          <Typography variant="h4">{t("devices.title")}</Typography>
        </Grid>
      )}
      <Grid item xs={9}>
        <List
          headCells={headCells}
          startStop={startFunction}
          devices={devices}
          apiAsyncFunc={apiAsyncFunc}
          loading={loading}
          showDetails={showDetails}
          setShowDetails={setShowDetails}
        />
      </Grid>
    </Grid>
  );
};

export default DeviceList;
