import {
  Grid,
  Paper,
  Table,
  TableContainer,
  TablePagination,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { DeviceListProps, ListProps } from "../../@types/allTypes";
import CustomButton from "../../Molecules/CustomButton/CustomButton";
import EnhancedTableToolbar from "../../Molecules/EnhancedTableToolbar/EnhancedTableToolbar";
import Details from "../../Pages/Details/Details";
import EnhancedTableBody from "../EnhancedTableBody/EnhancedTableBody";
import EnhancedTableHead from "../EnhancedTableHead/EnhancedTableHead";

const List = ({
  headCells,
  devices,
  apiAsyncFunc,
  loading,
  showDetails,
  setShowDetails,
}: ListProps) => {
  const [order, setOrder] = useState<"asc" | "desc">("asc");
  const [orderBy, setOrderBy] = useState("name");
  const [selected, setSelected] = useState<Array<DeviceListProps>>([]);
  const [selectedOffline, setSelectedOffline] = useState<
    Array<DeviceListProps>
  >([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [rows, setRows] = useState(devices);
  const { t } = useTranslation();

  const activeText = t("common.activeText");
  const inactiveText = t("common.inactiveText");
  const disabledText = t("common.disabledText");

  useEffect(() => {
    setRows(devices);
    const newArray = rows.filter((row) =>
      selected.some((item) => item.id === row.id),
    );
    setSelected(newArray);
  }, [devices]);

  // Returns all selected items of chosen type
  const getSelected = (type: number): Array<DeviceListProps> => {
    switch (type) {
      case 99:
        return selected;
      case 1:
        return selected.filter((item) => item.status === 1);
      case 0:
        return selected.filter((item) => item.status === 0);
      case -1:
        return rows.filter((item) => item.status === -1);
      case 4:
        return rows.filter((item) => {
          let valueRemain = -1;
          if (typeof item.remain === "string") {
            valueRemain = -1;
          } else {
            valueRemain = Math.floor(item.remain);
          }
          return valueRemain <= 15 && valueRemain !== -1;
        });
      default:
        break;
    }
    return [];
  };

  const handleRequestSort = (event: any, property: string) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: any) => {
    if (event.target.checked) {
      setSelected(rows.filter((item: any) => item.status !== -1));
      setSelectedOffline(rows.filter((item: any) => item.status === -1));
      return;
    }
    setSelected([]);
    setSelectedOffline([]);
  };

  const handleChangePage = (event: any, newPage: any) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return !showDetails ? (
    <div className="tableList root">
      <Paper className="paper">
        <EnhancedTableToolbar numSelected={selected.length} />
        <TableContainer className="tableContainer">
          <Table
            className="table"
            aria-labelledby="tableTitle"
            size="small"
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              headCells={headCells}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length - getSelected(-1).length}
            />
            <EnhancedTableBody
              selected={selected}
              setSelected={setSelected}
              selectedOffline={selectedOffline}
              setSelectedOffline={setSelectedOffline}
              order={order}
              orderBy={orderBy}
              page={page}
              rows={rows}
              rowsPerPage={rowsPerPage}
              activeText={activeText}
              inactiveText={inactiveText}
              disabledText={disabledText}
              dense
              setShowDetails={setShowDetails}
              loading={loading}
            />
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 50]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      <Grid
        item
        container
        xs={12}
        spacing={2}
        justifyContent="flex-end"
        className="buttonGroup"
      >
        <Grid item className="listButton">
          <CustomButton
            disabled={!(getSelected(4).length > 0)}
            sx={{ backgroundColor: "red" }}
            variant="contained"
            onClick={() =>
              window.open(
                `https://revoltab.com/?add-to-cart=${
                  getSelected(4)[0].shopId
                }&quantity=1`,
                "_blank",
              )
            }
          >
            {t("deviceDetails.purchaseTabs")} ({getSelected(4).length})
            {/* </Link> */}
          </CustomButton>
        </Grid>
        <Grid item className="listButton">
          <CustomButton
            disabled={!(selected.length > 0) || loading}
            color="primary"
            variant="contained"
            onClick={() => setShowDetails(true)}
          >
            {t("common.selected")} {t("common.details")} (
            {getSelected(99).length}){/* </Link> */}
          </CustomButton>
        </Grid>
      </Grid>
    </div>
  ) : (
    <Details
      selected={selected}
      devices={devices}
      setShowDetails={setShowDetails}
      showDetails={showDetails}
      apiAsyncFunc={apiAsyncFunc}
    />
  );
};
export default List;
