import { Grid, Typography } from "@mui/material";
import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { loginUser } from "../../config/services/requestService";
import Form from "../../Molecules/Form/Form";
import { login, isLogin } from "../../config/services/funcs";
import { AxiosAccountResponse, FormTypes } from "../../@types/allTypes";

const Login = () => {
  const { t } = useTranslation();
  const history = useNavigate();
  // const { keycloak } = useKeycloak();
  const isAuthed = isLogin();
  if (isAuthed.logged) {
    history("/");
  }
  const buttonFunc = async (
    values: FormTypes,
    setLoggingIn: React.Dispatch<React.SetStateAction<boolean>>,
  ) => {
    try {
      const response: AxiosAccountResponse = await loginUser(
        values.value1,
        values.value2,
      );

      if (response.status === 200) {
        // console.log(response);
        const isLogged = login(response);
        if (isLogged) {
          toast.success(t("login.loggedToast"));
          history("/");
        } else {
          toast.error(response.data.userId);
        }
      } else {
        toast.error(response.data.userId);
      }
      setLoggingIn(false);
    } catch (error) {
      setLoggingIn(false);
      toast.error("Error logging in");
    }
  };

  return (
    <Grid container className="loginPage" justifyContent="space-evenly">
      <Grid
        item
        container
        xs={12}
        justifyContent="space-between"
        className="title"
      >
        <Grid item xs={12}>
          <Typography variant="h4">{t("login.title")}</Typography>
        </Grid>
      </Grid>
      <Grid item container xs={12} alignItems="center" justifyContent="center">
        <Grid item xs={12} className="form">
          <Form
            maxWidth="500px"
            field1={t("common.email")}
            field1Type="email"
            field2={t("common.password")}
            field2Type="password"
            buttonText={t("common.submit")}
            // buttonFunc={async (values, setLoggingIn) => {
            //   await keycloak
            //     .register()
            //     .then((result) => {
            //       console.log(result);
            //     })
            //     .catch((error) => {
            //       console.error(error);
            //     });
            // }}
            buttonFunc={(values, setLoggingIn) =>
              buttonFunc(values, setLoggingIn)
            }
          />
        </Grid>

        <Grid item xs={12} className="description">
          <Typography variant="h5" className="title">
            {t("login.description.title")}
          </Typography>
          <Typography variant="h6" className="text">
            <Trans t={t} i18nKey="login.description.body">
              <b>Let’s start your sensoric journey in just a few steps</b>
            </Trans>
          </Typography>
          <Typography variant="subtitle2" className="text">
            <Trans t={t} i18nKey="login.description.downloadTitle" />
          </Typography>
          <Typography variant="subtitle2" className="text">
            <Trans t={t} i18nKey="login.description.downloadBody">
              Download the <b>revoltab APP</b> at the{" "}
              <b>Apple or Google Play Store.</b>
            </Trans>
          </Typography>
        </Grid>
      </Grid>
      <Grid
        container
        item
        xs={6}
        className="downloadIcons"
        sx={{ textAlign: "center" }}
      >
        <Grid item xs={12}>
          <a
            href="https://play.google.com/store/apps/details?id=com.revoltab.app"
            target="_blank"
            rel="noreferrer"
          >
            <img src="/google-play-badge.png" alt="google badge" />
          </a>
        </Grid>
        <Grid item xs={12}>
          <a
            href="https://apps.apple.com/app/revoltab/id1611521349"
            target="_blank"
            rel="noreferrer"
          >
            <img src="/appstore-badge.svg" alt="apple badge" />
          </a>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Login;
