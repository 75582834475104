import {
  LoginType,
  ListDevice,
  DeviceProps,
  AxiosAccountResponse,
  ChipData,
} from "../../@types/allTypes";
import {
  deviceAction,
  getDevices,
  refreshAccountToken,
} from "./requestService";

const delay = (milliseconds: number) => {
  return new Promise((resolve) => {
    setTimeout(resolve, milliseconds);
  });
};

const TOKEN_KEY = "accessToken";

const createData = (
  name: string,
  scent: string,
  status: number,
  intensity: string,
  remain: number,
  attributes: any,
  id: string,
  roomSize: number,
  isSub: boolean,
  aferoData: string,
  shopId: number | null,
) => {
  return {
    name,
    scent,
    status,
    intensity,
    remain,
    attributes,
    id,
    roomSize,
    isSub,
    aferoData,
    shopId,
  };
};

export const login = (props: AxiosAccountResponse) => {
  const now = new Date();
  const newProps = props.data;
  newProps.expires_in = newProps.expires_in * 1000 + now.getTime();
  newProps.refresh_expires_in =
    newProps.refresh_expires_in * 1000 + now.getTime();
  localStorage.setItem(TOKEN_KEY, JSON.stringify(newProps));
  return true;
};

export const logout = () => {
  localStorage.removeItem(TOKEN_KEY);
};

export const getCookie = () => {
  const localItem = localStorage.getItem(TOKEN_KEY);

  if (!localItem) {
    return false;
  }
  return JSON.parse(localItem);
};

export const doRefreshToken = async () => {
  const localItem = JSON.parse(localStorage.getItem(TOKEN_KEY) || "");
  const refresh = await refreshAccountToken(localItem.refresh_token);
  const now = new Date();
  const newProps = refresh.data;
  newProps.expires_in = newProps.expires_in * 1000 + now.getTime();
  localStorage.setItem(TOKEN_KEY, JSON.stringify(newProps));
};

// export const isLogin = () => {
//   const now = new Date();
//   let localItem = localStorage.getItem(TOKEN_KEY) || "";
//   if (localItem === "") {
//     return { logged: false, action: "logout" };
//   }
//   const loginItem = JSON.parse(localItem) as LoginType;
//   if (Object.keys(loginItem).length === 0) {
//     localStorage.removeItem(TOKEN_KEY);
//     return { logged: false, action: "logout" };
//   }

//   if (loginItem.expires_in > now.getTime()) {
//     return { logged: true, action: "none" };
//   } else {
//     if (loginItem.refresh_expires_in > now.getTime()) {
//       return { logged: false, action: "refresh" };
//     }
//     localStorage.removeItem(TOKEN_KEY);
//     return { logged: false, action: "logout" };
//   }
// };

export const isLogin = () => {
  const now = new Date();
  const localItem = localStorage.getItem(TOKEN_KEY) || "";
  if (localItem === "") {
    return { logged: false, action: "logout" };
  }
  const loginItem = JSON.parse(localItem) as LoginType;
  if (Object.keys(loginItem).length === 0) {
    localStorage.removeItem(TOKEN_KEY);
    return { logged: false, action: "logout" };
  }
  const { expires_in: expiresIn, refresh_expires_in: refreshExpiresIn } =
    loginItem;

  if (expiresIn > now.getTime()) {
    return {
      logged: true,
      action: "none",
      isAdmin: loginItem.accountId === "41fd13ce-3555-48f2-9f0a-837cea16f66a",
      accessToken: loginItem.access_token,
    };
  }
  if (refreshExpiresIn > now.getTime()) {
    return { logged: false, action: "refresh" };
  }
  localStorage.removeItem(TOKEN_KEY);
  return { logged: false, action: "logout" };
};

export const updateDeviceIntensity = async (
  listDevices: Array<ListDevice>,
  intensity: number,
): Promise<any> => {
  const cookie = getCookie();
  if (cookie) {
    /* eslint-disable no-await-in-loop */
    for (let i = 0; i < listDevices.length; i += 1) {
      const attributeChange = {
        type: "attribute_write" as const,
        attrId: 4,
        data: intensity.toString(),
      };

      await deviceAction(
        cookie.accountId,
        listDevices[i],
        attributeChange,
        null,
        cookie.access_token,
        cookie.refresh_token,
        "false",
      )
        .then((response) => {
          const { data } = response;
          return {
            status: data.status,
            data: data.data,
          };
        })
        .catch((e) => {
          return { status: 400, data: "Unkown Error" };
        });
      await delay(750);
    }
    /* eslint-enable no-await-in-loop */
  } else {
    return { status: 400, data: "Unkown Error" };
  }

  return { status: 200, data: "Updated successfully" };
};

export const startStopEjectDevice = async (
  listDevices: Array<ListDevice>,
  action: number,
) => {
  const cookie = getCookie();
  if (cookie) {
    if (action === 1) {
      /* eslint-disable no-await-in-loop */
      for (let i = 0; i < listDevices.length; i += 1) {
        const attributeChange = {
          type: "attribute_write" as const,
          attrId: 1,
          data: "1",
        };

        await deviceAction(
          cookie.accountId,
          listDevices[i],
          attributeChange,
          null,
          cookie.access_token,
          cookie.refresh_token,
          "false",
          [],
        )
          .then((response) => {
            const { data } = response;
            return {
              status: data.status,
              data: data.data,
            };
          })
          .catch((e) => {
            return { status: 400, data: "Unkown Error" };
          });
        await delay(500);
      }
      /* eslint-enable no-await-in-loop */
      return { status: 200, data: "Started successfully" };
    }
    if (action === 0) {
      /* eslint-disable no-await-in-loop */
      for (let i = 0; i < listDevices.length; i += 1) {
        const attributeChange = {
          type: "attribute_write" as const,
          attrId: 1,
          data: "0",
        };
        await deviceAction(
          cookie.accountId,
          listDevices[i],
          attributeChange,
          null,
          cookie.access_token,
          cookie.refresh_token,
          "false",
          [],
        )
          .then((response) => {
            const { data } = response;
            return {
              status: data.status,
              data: data.data,
            };
          })
          .catch((e) => {
            return { status: 400, data: "Unkown Error" };
          });
        await delay(750);
      }
      /* eslint-enable no-await-in-loop */
      return { status: 200, data: "Stopped successfully" };
    }
    if (action === -1) {
      /* eslint-disable no-await-in-loop */
      for (let i = 0; i < listDevices.length; i += 1) {
        const attributeChange = {
          type: "attribute_write" as const,
          attrId: 5,
          data: "1",
        };
        await deviceAction(
          cookie.accountId,
          listDevices[i],
          attributeChange,
          null,
          cookie.access_token,
          cookie.refresh_token,
          "false",
          [],
        )
          .then((response) => {
            const { data } = response;
            return {
              status: data.status,
              data: data.data,
            };
          })
          .catch((e) => {
            return { status: 400, data: "Unkown Error" };
          });
        await delay(750);
      }
      /* eslint-enable no-await-in-loop */
      return { status: 200, data: "Ejected successfully" };
    }
  }
  return { status: 400, data: "Unknown error" };
};

function shallowEqual(object1: any, object2: any) {
  const keys1 = Object.keys(object1);
  const keys2 = Object.keys(object2);
  if (keys1.length !== keys2.length) {
    return false;
  }
  // eslint-disable-next-line no-restricted-syntax
  for (const key of keys1) {
    if (object1[key] !== object2[key]) {
      return false;
    }
  }
  return true;
}

const arrayEquals = (a: any, b: any) => {
  let isContentEqual = true;
  if (typeof a === "object" && typeof b === "object") {
    isContentEqual = shallowEqual(a, b);
  }
  const equalArray =
    Array.isArray(a) &&
    Array.isArray(b) &&
    a.length === b.length &&
    a.every((val: any, index: any) => val === b[index]);
  for (let x = 0; x < a.length; x += 1) {
    const element = a[x];
    if (element !== b[x]) return false;

    if (Array.isArray(element) && Array.isArray(b[x])) {
      if (!arrayEquals(element, b[x])) return false;
    }
  }

  return equalArray && isContentEqual;
};

export const updateDeviceDetails = async (
  listDevices: Array<ListDevice>,
  schedule: ChipData[],
  totalOriginalEvents: number[],
): Promise<any> => {
  const cookie = getCookie();
  if (cookie) {
    /* eslint-disable no-await-in-loop */
    for (let i = 0; i < listDevices.length; i += 1) {
      // Need to know the real ID for the schedules
      const attributeChange = {
        type: "attribute_write" as const,
        attrId: 25,
        data: schedule,
      };

      await deviceAction(
        cookie.accountId,
        listDevices[i],
        null,
        attributeChange,
        cookie.access_token,
        cookie.refresh_token,
        "true",
        totalOriginalEvents,
      )
        .then((response) => {
          const { data } = response;
          return {
            status: data.status,
            data: data.data,
          };
        })
        .catch((e) => {
          return { status: 400, data: "Unkown Error" };
        });
      await delay(750);
    }
    /* eslint-enable no-await-in-loop */
  } else {
    return { status: 400, data: "Unkown Error" };
  }
  return { status: 200, data: "Updated successfully" };
};

export const getApiDevices = async () => {
  const cookie = getCookie();
  const logged = isLogin();
  if (logged.action === "refresh") {
    const response = await refreshAccountToken(cookie.refresh_token);
    login(response);
  }

  if (cookie) {
    const devicesToSet = await getDevices(
      cookie.accountId,
      cookie.access_token,
      cookie.refresh_token,
    );
    const { allDevices } = devicesToSet.data;
    if (allDevices) {
      const deviceList = allDevices.map((device) => {
        const id = device.deviceId;
        const { available } = device.deviceState;
        const name = device.friendlyName;
        const { roomSize } = device;
        const attributes = device.attributes || [];
        const { scentInfo } = device;
        const { isSub } = device;
        const { aferoData } = device;
        const { shopId } = device;
        const enabledAttribute = attributes.find((item: any) => {
          return device.deviceId === "0123ae3e93e9ead2"
            ? item.id === 1024
            : item.id === 1;
        });
        const intensity = attributes.find((item: any) => {
          return item.id === 4;
        });
        const remain = device.fillLevel;
        return createData(
          name || "-",
          scentInfo.length > 0 ? scentInfo[0].name : "-",
          // eslint-disable-next-line no-nested-ternary
          available ? (enabledAttribute?.value === "1" ? 1 : 0) : -1,
          intensity?.value || 100,
          remain || 0,
          attributes,
          id,
          roomSize || 0,
          isSub,
          aferoData,
          shopId,
        );
      });
      window.localStorage.setItem("devices", JSON.stringify(deviceList));
      return deviceList;
    }
  }
  return [];
};

/* < 10m2 /Fan Intensity  bei 1 20% 2 30% 3 40% 4 50%
  10m2 - 19m2 /Fan Intensity  bei 1 30% 2 40% 3 50% 4 60%
  20m2-29m2 /Fan Intensity  bei 1 40% 2 50% 3 60% 4 70%
  30m2-39m2 /Fan Intensity  bei 1 50% 2 60% 3 70% 4 80%
  >= 40m2 /Fan Intensity  bei 1 60% 2 70% 3 80% 4 100% */

export const calcIntervalModeLabel = (intensity: number, size = 0) => {
  let calcRealIntensity = intensity || 0;
  let intervalLabel = "";
  if (size > 0) {
    if (size < 10) {
      calcRealIntensity = (intensity / 10 - 1) * 20;
    } else if (size < 20) {
      calcRealIntensity = (intensity / 10 - 2) * 20;
    } else if (size < 30) {
      calcRealIntensity = (intensity / 10 - 3) * 20;
    } else if (size < 40) {
      calcRealIntensity = (intensity / 10 - 4) * 20;
    } else {
      calcRealIntensity = (intensity / 10 - 5) * 20;
      if (intensity === 100) {
        calcRealIntensity = 80;
      }
    }
  }

  switch (calcRealIntensity) {
    case 20:
      intervalLabel = "deviceDetails.intensityOne";
      break;
    case 40:
      intervalLabel = "deviceDetails.intensityTwo";
      break;
    case 60:
      intervalLabel = "deviceDetails.intensityThree";
      break;
    case 80:
      intervalLabel = "deviceDetails.intensityFour";
      break;
    default:
      intervalLabel = "deviceDetails.expert";
  }

  return intervalLabel;
};

export const checkIntervalSet = (intervalSet: string) => {
  let intervalIntensity = 0;
  let timeOn = 0;
  let timeOff = 0;
  let repeats = 0;
  if (intervalSet !== "0000000000000000") {
    const valueSplit: string[] = Array.from(intervalSet);
    repeats = parseInt("".concat(valueSplit[2], valueSplit[3]), 16);
    intervalIntensity = parseInt("".concat(valueSplit[4], valueSplit[5]), 16);
    timeOff =
      parseInt(
        "".concat(valueSplit[8], valueSplit[9], valueSplit[6], valueSplit[7]),
        16,
      ) / 60;
    timeOn =
      parseInt(
        "".concat(
          valueSplit[12],
          valueSplit[13],
          valueSplit[10],
          valueSplit[11],
        ),
        16,
      ) / 60;
  }
  return { repeats, intervalIntensity, timeOff, timeOn };
};
