import React from "react";
import { Grid, Typography } from "@mui/material";
import { LinkProps, Links } from "../../@types/allTypes";

const Footer = ({ links, place }: Links) => {
  return (
    <footer className="footerGeneral">
      <Grid
        className="grid"
        container
        justifyContent="flex-end"
        alignContent="center"
      >
        <Grid item xs={3}>
          {links.map((item: LinkProps) => {
            if (!item.hide.includes(place))
              return (
                <Typography
                  color="primary"
                  key={item.text}
                  component="span"
                  variant="h6"
                >
                  <a
                    style={{ padding: "5px", color: "inherit" }}
                    href={item.url}
                  >
                    {item.text}
                  </a>
                </Typography>
              );
            return <React.Fragment key={item.url} />;
          })}
        </Grid>
      </Grid>
    </footer>
  );
};

export default Footer;
