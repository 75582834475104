import { Fade, Grid, LinearProgress, Typography } from "@mui/material";
import { createContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import {
  ChipData,
  ChipDataSdk,
  DetailsType,
  ListDevice,
  ScheduleListProps,
} from "../../@types/allTypes";
import {
  startStopEjectDevice,
  updateDeviceDetails,
  updateDeviceIntensity,
} from "../../config/services/funcs";
import CustomButton from "../../Molecules/CustomButton/CustomButton";
import IntenseSlider from "../../Molecules/IntenseSlider/IntenseSlider";
import Scheduler from "../../Organisms/Scheduler/Scheduler";
import SideList from "../../Organisms/SideList/SideList";

export const ChipContext = createContext<ChipData[]>([]);

type TimeRange = {
  timeFrom: string;
  timeTo: string;
  interval: number;
  intensity: number;
};

const Details = ({
  selected,
  devices,
  setShowDetails,
  showDetails,
  apiAsyncFunc,
}: DetailsType) => {
  const { t, i18n } = useTranslation();
  const [rowsSelected, setRowsSelected] = useState(selected || []);
  const scheduleChips: ChipData[] = [];
  const noSelected = rowsSelected.length < 1;
  // console.log("rowsSelected: ", rowsSelected)
  let isDisabled = false;
  let totalOriginalEvents: number[] = [];
  // console.log(rowsSelected);
  const attributeSchedule = rowsSelected[0]?.attributes.filter((attribute) => {
    if (attribute.id === 59001) {
      if (attribute.value === "0") {
        isDisabled = true;
      }
    }
    return attribute.id > 59001 && attribute.id < 59100;
  });

  totalOriginalEvents = [];
  const chipTimeDay = new Map<string, TimeRange[]>();
  attributeSchedule?.forEach((scheduleChip: ScheduleListProps) => {
    const chip: ChipDataSdk = scheduleChip.data;
    if (typeof chip === "object") {
      totalOriginalEvents.push(scheduleChip.id);
      const chipDaysString = chip.days.join(",");
      const timeRangeArr = chipTimeDay.get(chipDaysString);
      if (timeRangeArr) {
        timeRangeArr.push({
          timeFrom: chip.timeFrom,
          timeTo: chip.timeTo,
          interval: chip.interval,
          intensity: chip.intensity,
        });
        chipTimeDay.set(chipDaysString, timeRangeArr);
      } else {
        chipTimeDay.set(chipDaysString, [
          {
            timeFrom: chip.timeFrom,
            timeTo: chip.timeTo,
            interval: chip.interval,
            intensity: chip.intensity,
          },
        ]);
      }
    }
  });

  const overallIntensity: number = parseInt(
    rowsSelected[0]?.attributes?.find((item) => {
      return item.id === 4;
    })?.value || "",
  );

  const [isLoading, setIsLoading] = useState<boolean>(!!noSelected);
  const [intensity, setIntensity] = useState<number>(overallIntensity || 0);
  const [query, setQuery] = useState("idle");

  const minIntensity = 0;
  const maxIntensity = 10;

  const sendIntensity = async (int: number) => {
    setIsLoading(true);
    setQuery("progress");
    const devices: Array<any> = JSON.parse(
      window.localStorage.getItem("devices") || "[]",
    );
    const listDevices = rowsSelected;

    const listToAddState: Array<any> = [];
    listDevices.forEach((element: any) => {
      const currentElement = element;
      if (element.status !== 1) {
        currentElement.status = 1;
      }

      listToAddState.push(currentElement);
    });

    setRowsSelected(listToAddState);
    rowsSelected.forEach((element: any) => {
      let currentIndex = 0;
      const currentDevice = devices.find((device, index) => {
        if (device.id === element.id) {
          currentIndex = index;
          return true;
        }
      });
      currentDevice.attributes[3].value = int.toString();
      devices[currentIndex] = currentDevice;
    });
    window.localStorage.setItem("devices", JSON.stringify(devices));

    const funcResponse = await startStopEjectDevice(
      rowsSelected,
      int === 0 ? 0 : 1,
    );
    const result = await updateDeviceIntensity(rowsSelected, int);

    setTimeout(() => {
      // apiAsyncFunc()
      if (result?.status === 200 || result?.status === 204) {
        toast.success(result.data);
      } else {
        toast.error(result.data);
      }
      setQuery("success");
      setIsLoading(false);
    }, 1000);
  };

  const getSelected = (type: number) => {
    switch (type) {
      case 99:
        return rowsSelected;
      case 1:
        return rowsSelected.filter((item) => item.status === 1);
      case 0:
        return rowsSelected.filter((item) => item.status === 0);
      case -1:
        return rowsSelected.filter((item) => item.status === -1);
      case 4:
        return rowsSelected.filter((item) => {
          let valueRemain = -1;
          if (typeof item.remain === "string") {
            valueRemain = -1;
          } else {
            valueRemain = Math.floor(item.remain);
          }
          return valueRemain <= 15 && valueRemain !== -1;
        });
      default:
        return [];
    }
  };

  const getAllFilter = (type: number) => {
    switch (type) {
      case 99:
        return devices;
      case 1:
        return devices.filter((item) => item.status === 1);
      case 0:
        return devices.filter((item) => item.status === 0);
      case -1:
        return devices.filter((item) => item.status === -1);
      case 4:
        return devices.filter((item) => {
          let valueRemain = -1;
          if (typeof item.remain === "string") {
            valueRemain = -1;
          } else {
            valueRemain = Math.floor(item.remain);
          }
          return valueRemain <= 15 && valueRemain !== -1;
        });
      default:
        return [];
    }
  };

  //   rows: JSON.stringify(selected)
  const startFunction = async (listDevices: ListDevice[], action: number) => {
    setIsLoading(true);
    if (action === -1) {
      await startStopEjectDevice(listDevices, action);
      setQuery("progress");
      setTimeout(() => {
        setQuery("success");
        setIsLoading(false);
        // apiAsyncFunc()
      }, 5000);
    } else if (action === 0) {
      setIntensity(0);
      const listToAddState: Array<any> = [];
      listDevices.forEach((element: any) => {
        const currentElement = element;
        if (element.status !== action) {
          currentElement.status = action;
        }
        currentElement.intensity = "0";
        listToAddState.push(currentElement);
      });
      setRowsSelected(listToAddState);
      const funcResponse = await startStopEjectDevice(listDevices, action);
      const funcIntResponse = await updateDeviceIntensity(listDevices, 0);
      setIsLoading(false);
      if (
        (funcResponse.status === 200 || funcResponse.status === 204) &&
        (funcIntResponse.status === 200 || funcIntResponse.status === 204)
      ) {
        toast.success(funcResponse.data);
      } else {
        toast.error(funcResponse.data);
      }
    } else {
      setIntensity(10);
      const listToAddState: Array<any> = [];
      listDevices.forEach((element: any) => {
        const currentElement = element;
        if (element.status !== action) {
          currentElement.status = action;
        }
        currentElement.intensity = "10";
        listToAddState.push(currentElement);
      });
      setRowsSelected(listToAddState);
      const funcResponse = await startStopEjectDevice(listDevices, action);
      const funcIntResponse = await updateDeviceIntensity(listDevices, 10);
      setIsLoading(false);
      if (
        (funcResponse.status === 200 || funcResponse.status === 204) &&
        (funcIntResponse.status === 200 || funcIntResponse.status === 204)
      ) {
        toast.success(funcResponse.data);
      } else {
        toast.error(funcResponse.data);
      }
    }
  };

  const scheduleList = async (list: ChipData[]) => {
    setIsLoading(true);
    const result = await updateDeviceDetails(
      rowsSelected,
      list,
      totalOriginalEvents,
    );
    setTimeout(() => {
      if (result?.status === 200) {
        toast.success(result.data);
      } else {
        toast.error(result.data);
      }
      setIsLoading(false);
      apiAsyncFunc();
    }, 1000);
  };
  chipTimeDay.forEach((value, key) => {
    scheduleChips.push({
      type: "auto",
      interval: value[0].interval || 50,
      times: value,
      intensity: value[0].intensity,
      days: key.split(",").map((num) => {
        return parseInt(num);
      }),
    });
  });

  return (
    <Grid container className="deviceDetails">
      <Grid item className="title" xs={7} style={{ paddingLeft: "15px" }}>
        <Typography variant="h4">{t("deviceDetails.title")}</Typography>
      </Grid>
      <Grid item className="title" xs={5}>
        <Typography variant="h4">{t("deviceDetails.scentTitle")}</Typography>
      </Grid>
      <Grid
        item
        container
        xs={12}
        sm={7}
        justifyContent="center"
        alignItems="flex-start"
        direction="row"
      >
        <Grid item sm={11}>
          <SideList
            rowsSelected={rowsSelected}
            rowsDisabled={getAllFilter(-1)}
            setIsLoading={setIsLoading}
            noSelected={noSelected}
          />
        </Grid>
        <Grid item sm={11}>
          <IntenseSlider
            value={intensity}
            setIntensity={(value) => {
              setIntensity(value * 10);
              sendIntensity(value * 10);
            }}
            disabled={isLoading}
            min={minIntensity}
            max={maxIntensity}
          />
        </Grid>
        <Grid item xs={11} sm={11} className="buttonGroup">
          <Grid item container xs={12} spacing={2} justifyContent="flex-end">
            <Grid item xs={6} sm={3}>
              <CustomButton
                onClick={() => startFunction(getSelected(0), 1)}
                disabled={!(getSelected(0).length > 0) || isLoading}
                color="primary"
                variant="contained"
                fullWidth
              >
                {t("common.start")} ({getSelected(0).length})
              </CustomButton>
            </Grid>
            <Grid item xs={6} sm={3}>
              <CustomButton
                onClick={() => startFunction(getSelected(1), 0)}
                disabled={!(getSelected(1).length > 0) || isLoading}
                color="primary"
                variant="contained"
                fullWidth
              >
                {t("common.stop")} ({getSelected(1).length})
              </CustomButton>
            </Grid>
            <Grid item xs={6} sm={3}>
              <>
                <Fade
                  in={query === "progress"}
                  style={{
                    transitionDelay: query === "progress" ? "800ms" : "0ms",
                  }}
                  unmountOnExit
                >
                  <LinearProgress variant="indeterminate" />
                </Fade>
                <CustomButton
                  onClick={() => startFunction(getSelected(99), -1)}
                  disabled={!(getSelected(99).length > 0) || isLoading}
                  color="primary"
                  variant="contained"
                  fullWidth
                >
                  {t("common.eject")} ({getSelected(99).length})
                </CustomButton>
              </>
            </Grid>
            <Grid item xs={6} sm={3}>
              <CustomButton
                disabled={isLoading}
                sx={
                  getSelected(4).length > 0
                    ? { backgroundColor: "red" }
                    : { backgroundColor: "var(--primaryMain)" }
                }
                variant="contained"
                onClick={() =>
                  window.open(
                    `https://revoltab.com/?add-to-cart=${
                      getSelected(99)[0].shopId
                    }&quantity=1`,
                    "_blank",
                  )
                }
                fullWidth
              >
                {t("deviceDetails.purchaseTabs")} ({getSelected(99).length})
              </CustomButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item container xs={12} sm={5} justifyContent="center">
        <ChipContext.Provider value={scheduleChips}>
          <Scheduler
            scheduleList={scheduleList}
            isLoading={isLoading}
            noSelected={noSelected}
            setIsLoading={setIsLoading}
            minIntensity={minIntensity}
            maxIntensity={maxIntensity}
            setShowDetails={setShowDetails}
            roomSize={rowsSelected[0]?.roomSize}
          />
        </ChipContext.Provider>
      </Grid>
    </Grid>
  );
};

export default Details;
