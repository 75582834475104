import {
  FormGroup,
  Grid,
  Stack,
  Switch,
  Tooltip,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import IntenseSlider from "../../Molecules/IntenseSlider/IntenseSlider";
import SelectInterval from "../SchedulerInterval/SchedulerInterval";

// Set days of the week

const SelectIntensity = (
  minIntensity: number,
  maxIntensity: number,
  currentIntensity: number,
  setCurrentIntensity: React.Dispatch<React.SetStateAction<number>>,
  currentInterval: number,
  setCurrentInterval: React.Dispatch<React.SetStateAction<number>>,
  schedulerType: "auto" | "expert",
  setCurrentType: React.Dispatch<React.SetStateAction<"auto" | "expert">>,
) => {
  const { t } = useTranslation();

  const handleChangeType = () => {
    setCurrentType(schedulerType === "auto" ? "expert" : "auto");
  };
  return (
    <FormGroup>
      <Grid container className="formIntense" justifyContent="center">
        <Grid item md={12}>
          <Stack
            direction="row"
            spacing={1}
            alignItems="center"
            justifyContent="flex-end"
          >
            <Typography>Standard</Typography>
            <Switch
              value={schedulerType}
              onChange={handleChangeType}
              inputProps={{ "aria-label": "ant design" }}
            />
            <Tooltip title={t("scheduler.expertDetails")} arrow>
              <Typography>Expert</Typography>
            </Tooltip>
          </Stack>
        </Grid>

        {schedulerType === "expert" && (
          <Grid item xs={10}>
            <IntenseSlider
              value={currentIntensity}
              setIntensity={(value) => {
                setCurrentIntensity(value * 10);
              }}
              disabled={false}
              min={minIntensity}
              max={maxIntensity}
            />
          </Grid>
        )}
        <Grid item xs={10} sx={{ textAlign: "right" }}>
          {SelectInterval(
            schedulerType,
            setCurrentType,
            currentInterval,
            setCurrentInterval,
          )}
        </Grid>
      </Grid>
    </FormGroup>
  );
};
export default SelectIntensity;
