import {
  Button,
  FormControl,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { Trans, useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { submitContact } from "../../config/services/requestService";

const Contact = () => {
  const history = useNavigate();

  const [isDisabled, setIsDisabled] = useState(true);
  const [contactForm, setContactForm] = useState({
    fName: "",
    sName: "",
    email: "",
    subject: "",
    message: "",
  });
  const { t } = useTranslation();

  const onChange = (
    e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const { id } = e.currentTarget;
    const { value } = e.currentTarget;
    setContactForm({ ...contactForm, [id]: value });
  };

  const submitForm = async () => {
    const contactResponse = await submitContact(contactForm);
    if (contactResponse.data.status === 200) {
      toast.success("Message sent successfully");
      history("/");
    } else {
      toast.error(contactResponse.data.data);
    }
  };

  return (
    <Grid
      className="contactPage"
      container
      justifyContent="center"
      alignItems="center"
    >
      <Grid item xs={12} className="title">
        <Typography variant="h3">{t("contact.title")}</Typography>
      </Grid>
      <Grid item xs={10} className="body">
        <Typography variant="h6">
          <form
            onSubmit={(e) => {
              e.preventDefault();
              submitForm();
            }}
          >
            <Grid item xs={12} container spacing={1}>
              <Grid item xs={5} sm={4}>
                <FormControl
                  margin="normal"
                  required
                  variant="filled"
                  fullWidth
                  size="medium"
                >
                  <TextField
                    onChange={(e) => onChange(e)}
                    className="textContact"
                    label="First Name"
                    variant="outlined"
                    id="fName"
                    value={contactForm.fName}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={5} sm={4}>
                <FormControl
                  margin="normal"
                  required
                  variant="filled"
                  fullWidth
                  size="medium"
                >
                  <TextField
                    onChange={(e) => onChange(e)}
                    className="textContact"
                    label="Surname"
                    variant="outlined"
                    id="sName"
                    value={contactForm.sName}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={10} sm={8}>
                <FormControl
                  margin="normal"
                  required
                  variant="filled"
                  fullWidth
                  size="medium"
                >
                  <TextField
                    onChange={(e) => onChange(e)}
                    type="email"
                    className="textContact"
                    label="Email Address"
                    variant="outlined"
                    id="email"
                    value={contactForm.email}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={10} sm={8}>
                <FormControl
                  margin="normal"
                  required
                  variant="filled"
                  fullWidth
                  size="medium"
                >
                  <TextField
                    onChange={(e) => onChange(e)}
                    className="textContact"
                    label="Subject"
                    variant="outlined"
                    id="subject"
                    value={contactForm.subject}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={10} sm={10}>
                <FormControl
                  margin="normal"
                  required
                  variant="filled"
                  fullWidth
                  size="medium"
                >
                  <TextField
                    onChange={(e) => onChange(e)}
                    className="textContact"
                    label="Message"
                    variant="outlined"
                    rows={10}
                    id="message"
                    multiline
                    value={contactForm.message}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={10}>
                <ReCAPTCHA
                  sitekey="6Ldb1uEeAAAAAHXfBI2nFQLFUYJ3RaumlNfmvW0R"
                  onChange={() => {
                    setIsDisabled(!isDisabled);
                  }}
                />
              </Grid>
              <Grid item xs={10}>
                <Button
                  style={{ width: "180px", height: "50px" }}
                  variant="contained"
                  type="submit"
                  disabled={isDisabled}
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          </form>
        </Typography>
        <Grid item xs={10}>
          <Typography variant="h5">
            <Trans t={t} i18nKey="contact.contactUs">
              <b>Contact us for more information.</b>
            </Trans>
          </Typography>
          <Typography variant="h6">
            <a href="mailto:contact@revoltab.com">contact@revoltab.com</a>
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Contact;
