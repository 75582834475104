/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/destructuring-assignment */
import { Button, ButtonProps } from "@mui/material";

const CustomButton = (props: ButtonProps) => {
  return (
    <Button {...props} className={`${props.color} customButton`}>
      {props.children}
    </Button>
  );
};

export default CustomButton;
