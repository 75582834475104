import {
  FormControlLabel,
  FormGroup,
  Grid,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import { useEffect, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";

// Set days of the week

const SelectInterval = (
  currentType: "auto" | "expert",
  setCurrentType: React.Dispatch<React.SetStateAction<"auto" | "expert">>,
  currentInterval: number,
  setCurrentInterval: React.Dispatch<React.SetStateAction<number>>,
) => {
  const [type, setType] = useState(currentType);
  const { t } = useTranslation();
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentInterval(parseInt(event.target.value, 10));
  };

  const handleSetCurrentType = useCallback(
    (newType: "auto" | "expert") => {
      setCurrentType(newType);
    },
    [setCurrentType],
  );
  useEffect(() => {
    handleSetCurrentType(currentType);
    setType(currentType);
  }, [currentType, handleSetCurrentType]);

  const radioButtons = [
    { value: 20, label: t("deviceDetails.intensityOne") },
    { value: 40, label: t("deviceDetails.intensityTwo") },
    { value: 60, label: t("deviceDetails.intensityThree") },
    { value: 80, label: t("deviceDetails.intensityFour") },
  ];

  if (type === "auto") {
    if (currentInterval < 20) {
      setCurrentInterval(20);
    } else if (currentInterval < 40 && currentInterval > 20) {
      setCurrentInterval(40);
    } else if (currentInterval < 60 && currentInterval > 40) {
      setCurrentInterval(60);
    } else if (
      (currentInterval < 80 && currentInterval > 60) ||
      currentInterval > 80
    ) {
      setCurrentInterval(80);
    }
    return (
      <RadioGroup value={currentInterval} onChange={handleChange}>
        <Grid container justifyContent="flex-end">
          {radioButtons.map(({ value, label }) => (
            <Grid key={value} item xs={12}>
              <FormControlLabel
                value={value}
                control={<Radio value={value} />}
                label={label}
              />
            </Grid>
          ))}
        </Grid>
      </RadioGroup>
    );
  }
  return (
    <FormGroup>
      <Grid container justifyContent="center" item className="formIntense">
        <TextField
          id="interval"
          type="number"
          sx={{ width: "30%" }}
          defaultValue={currentInterval}
          inputProps={{
            min: 0,
            max: 100,
          }}
          InputLabelProps={{ shrink: true }}
          label="Interval in %"
          onChange={handleChange}
          required
        />
      </Grid>
    </FormGroup>
  );
};
export default SelectInterval;
