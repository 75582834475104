import http from "./http-common";
import {
  ListDevice,
  DeviceActionType,
  EmailType,
  DeviceProps,
  AxiosAccountResponse,
  TabType,
  HistoryDeviceDataType,
} from "../../@types/allTypes";

export const getAll = () => {
  return http.get("/v1/users/me");
};

export const getDevices = async (
  id: string,
  accessToken: string,
  refreshToken: string,
) => {
  const response: DeviceProps = await http.get(`/accounts/devices/${id}`, {
    params: { accessToken, refreshToken },
  });
  return response;
};

export const refreshAccountToken = async (refreshToken: string) => {
  const params = new URLSearchParams();
  params.append("refreshToken", refreshToken);
  const response: AxiosAccountResponse = await http.post(
    "/auth/refreshToken",
    params,
  );
  return response;
};

export const deviceAction = async (
  accountId: string,
  deviceData: ListDevice,
  data: DeviceActionType | null,
  schedulerData: DeviceActionType | null,
  accessToken: string,
  refreshToken: string,
  isScheduler = "false",
  originalEvents: number[] = [],
) => {
  const params = new URLSearchParams();
  params.append("account", accountId);
  params.append("data", JSON.stringify(data));
  params.append("schedulerData", JSON.stringify(schedulerData));
  params.append("deviceData", JSON.stringify(deviceData));
  params.append("originalEvents", JSON.stringify(originalEvents));
  params.append("isScheduler", isScheduler);
  params.append("accessToken", accessToken);
  params.append("refreshToken", refreshToken);
  const response = await http.post(`/devices`, params);
  return response;
};

export const loginUser = async (username: string, password: string) => {
  const params = new URLSearchParams();
  params.append("username", username);
  params.append("password", password);
  const response: AxiosAccountResponse = await http.post("/auth", params);
  return response;
};

export const submitContact = async (form: EmailType) => {
  const params = new URLSearchParams();
  params.append("form", JSON.stringify(form));
  const response: { data: { status: number; data: string } } = await http.post(
    "/mail",
    params,
  );
  return response;
};

export const sendHistoryDevices = async (
  historyDevices: HistoryDeviceDataType[],
  accessToken: string,
) => {
  const params = new URLSearchParams();
  params.append("data", JSON.stringify(historyDevices));
  params.append("accessToken", accessToken);
  const response = await http.post("/aferoDevices", params);
  return response;
};

export const sendTabData = async (tabs: TabType[], accessToken: string) => {
  const params = new URLSearchParams();
  params.append("data", JSON.stringify(tabs));
  params.append("accessToken", accessToken);
  const response = await http.post("/tab_import", params);
  return response;
};

export const updateRevoltabShop = async () => {
  const response = await http.post("/update_customers");
  return response;
};
