import { Add } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Chip,
  FormGroup,
  Grid,
  Typography,
} from "@mui/material";
import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers/";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import tz from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { SchedulerTime } from "../../@types/allTypes";

// Set days of the week

const SelectTime = (
  currentTimes: SchedulerTime[],
  setCurrentTimes: React.Dispatch<React.SetStateAction<SchedulerTime[]>>,
) => {
  const { t, i18n } = useTranslation();
  dayjs.extend(utc);
  dayjs.extend(tz);
  const [times, setTimes] = useState<SchedulerTime[]>(currentTimes || []);
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const [currentFromTime, setCurrentFromTime] = useState<string>("09:00");
  const [currentToTime, setCurrentToTime] = useState<string>("17:00");
  const searchTimeExists = () => {
    let result = false;
    if (currentToTime <= currentFromTime) return true;
    times.forEach((element) => {
      const { timeFrom, timeTo } = element;
      if (
        (currentFromTime >= timeFrom && currentFromTime < timeTo) ||
        (currentToTime <= timeTo && currentToTime > timeFrom) ||
        (currentToTime <= currentFromTime && currentToTime > timeFrom)
      ) {
        result = true;
      }
    });
    return result;
  };

  const handleAdd = () => {
    // If no Time overlap
    let finalFromTime = currentFromTime;
    let finalToTime = currentToTime;
    const fromHour = parseInt(currentFromTime.split(":")[0], 10);
    let fromMinute = parseInt(currentFromTime.split(":")[1], 10);
    const toHour = parseInt(currentToTime.split(":")[0], 10);
    let toMinute = parseInt(currentToTime.split(":")[1], 10);
    const extraMins = Math.abs(
      (toHour * 60 + toMinute - (fromHour * 60 + fromMinute)) % 15,
    );
    if (extraMins === 0 && fromMinute > 0 && fromMinute % 15 !== 0) {
      fromMinute -= fromMinute % 15;
      toMinute -= toMinute % 15;
      finalFromTime = `${fromHour.toLocaleString("en-US", {
        minimumIntegerDigits: 2,
      })}:${fromMinute.toLocaleString("en-US", {
        minimumIntegerDigits: 2,
      })}`;
      finalToTime = `${toHour.toLocaleString("en-US", {
        minimumIntegerDigits: 2,
      })}:${toMinute.toLocaleString("en-US", {
        minimumIntegerDigits: 2,
      })}`;
      setCurrentFromTime(finalFromTime);
      setCurrentToTime(finalToTime);
    }
    const searchResult = searchTimeExists();
    if (extraMins > 0) {
      toast.error("The times must be set in 15 minute intervals");
    } else if (!searchResult) {
      const newCurrentTimes = currentTimes;
      newCurrentTimes.push({
        timeFrom: finalFromTime,
        timeTo: finalToTime,
      });
      setTimes([...newCurrentTimes]);
      setCurrentTimes([...newCurrentTimes]);
    } else {
      // With time overlap
      toast.error(
        "Please select a new time, this one is overlapping or not valid",
      );
    }
  };

  const handleDelete = (index: number) => {
    const newCurrentTimes = times;
    newCurrentTimes.splice(index, 1);
    setTimes([...newCurrentTimes]);
    setCurrentTimes([...newCurrentTimes]);
  };

  useEffect(() => {
    setTimes(currentTimes);
  }, [currentTimes]);

  return (
    <FormGroup className="schedulerTimes">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h6">{t("deviceDetails.times")}</Typography>
          {times.length < 1 && (
            <Typography sx={{ color: "red" }}>
              {t("scheduler.minOne")}
            </Typography>
          )}
        </Grid>

        {times.map((time, index) => {
          return (
            <Grid key={time.timeFrom} item xs={6} md={4} lg={3}>
              <Chip
                color="primary"
                variant="outlined"
                size="small"
                onDelete={() => handleDelete(index)}
                label={`${time.timeFrom}-${time.timeTo}`}
              />
            </Grid>
          );
        })}
      </Grid>
      <Accordion expanded={isExpanded}>
        <AccordionSummary
          onClick={() => setIsExpanded(!isExpanded)}
          expandIcon={<Add color="warning" />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>{t("scheduler.newTime")}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={1} justifyContent="center">
            <Grid item xs={12} sm={5} className="formTimeFrom">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <TimePicker
                  onChange={(e) => {
                    setCurrentFromTime(e?.tz().format("HH:mm") || "");
                  }}
                  label="From"
                  ampm={i18n.language !== "de"}
                  value={dayjs(currentFromTime, "HH:mm")}
                  minutesStep={15}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12} sm={5} className="formTimeFrom">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <TimePicker
                  onChange={(e) => {
                    setCurrentToTime(e?.tz().format("HH:mm") || "");
                  }}
                  label="To"
                  ampm={i18n.language !== "de"}
                  value={dayjs(currentToTime, "HH:mm")}
                  minutesStep={15}
                />
              </LocalizationProvider>
              {/* <TextField
                sx={{ width: "80%" }}
                id="timeTo"
                type="time"
                defaultValue={currentToTime}
                required
                inputProps={{
                  step: 60,
                  min: minToTime,
                  max: maxToTime,
                }}
                InputLabelProps={{ shrink: true }}
                label="To"
                onChange={(e) => {
                  setCurrentToTime(e.currentTarget.value);
                }}
              /> */}
            </Grid>
            <Grid item xs={4}>
              <Button
                variant="outlined"
                onClick={handleAdd}
                sx={{ mr: 1, fontWeight: "bold" }}
              >
                {t("common.add")}
              </Button>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </FormGroup>
  );
};
export default SelectTime;
