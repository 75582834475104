import CardMembershipIcon from "@mui/icons-material/CardMembership";
import {
  Button,
  Checkbox,
  TableBody,
  TableCell,
  TableRow,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import {
  DeviceAttributeProps,
  DeviceListProps,
  EnhancedTableBodyType,
} from "../../@types/allTypes";
import {
  calcIntervalModeLabel,
  checkIntervalSet,
} from "../../config/services/funcs";

const EnhancedTableBody = ({
  selected,
  selectedOffline,
  setSelectedOffline,
  setSelected,
  rowsPerPage,
  rows,
  order,
  page,
  orderBy,
  activeText,
  inactiveText,
  disabledText,
  dense,
  setShowDetails,
  loading,
}: EnhancedTableBodyType) => {
  const { t } = useTranslation();
  const descendingComparator = (a: string, b: string, chooseOrder: any) => {
    if (b[chooseOrder] < a[chooseOrder]) {
      return -1;
    }
    if (b[chooseOrder] > a[chooseOrder]) {
      return 1;
    }
    return 0;
  };

  const getComparator = (currentOrder: string, chooseOrder: any) => {
    return currentOrder === "desc"
      ? (a: string, b: string) => descendingComparator(a, b, chooseOrder)
      : (a: string, b: string) => -descendingComparator(a, b, chooseOrder);
  };

  const stableSort = (array: Array<any>, comparator: any) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const newOrder = comparator(a[0], b[0]);
      if (newOrder !== 0) return newOrder;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  };

  // Function to handle the multiselect
  const handleClick = (id: string, status: number) => {
    if (status !== -1) {
      const selectedIndex = selected.findIndex((i) => i.id === id);
      let newSelected: DeviceListProps[] = [];

      if (selectedIndex === -1) {
        newSelected = newSelected.concat(
          selected,
          rows.filter((item) => item.id === id),
        );
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1));
      } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          selected.slice(0, selectedIndex),
          selected.slice(selectedIndex + 1),
        );
      }

      setSelected(newSelected);
    } else {
      const selectedIndex = selectedOffline.findIndex((i) => i.id === id);
      let newSelected: DeviceListProps[] = [];

      if (selectedIndex === -1) {
        newSelected = newSelected.concat(
          selectedOffline,
          rows.filter((item) => item.id === id),
        );
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selectedOffline.slice(1));
      } else if (selectedIndex === selectedOffline.length - 1) {
        newSelected = newSelected.concat(selectedOffline.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          selectedOffline.slice(0, selectedIndex),
          selectedOffline.slice(selectedIndex + 1),
        );
      }

      setSelectedOffline(newSelected);
    }
  };

  const isSelected = (id: string) =>
    selected.findIndex((i: any) => i.id === id) !== -1;

  const isSelectedOffline = (id: string) =>
    selectedOffline.findIndex((i: any) => i.id === id) !== -1;
  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  return (
    <TableBody className="listTableBody">
      {stableSort(rows, getComparator(order, orderBy))
        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        .map((row: DeviceAttributeProps, index: number) => {
          const intervalSet =
            row.attributes.filter((attribute) => {
              return attribute.id === 16;
            })[0]?.data || "0000000000000000";
          const { intervalIntensity, timeOff, timeOn, repeats } =
            checkIntervalSet(intervalSet);
          let statusText: string;
          let textColor: "red" | "green" | "black";
          if (
            row.status === 1 ||
            (intervalSet !== "0000000000000000" && row.status !== -1)
          ) {
            statusText = activeText;
            textColor = "green";
          } else if (row.status === 0) {
            statusText = inactiveText;
            textColor = "red";
          } else {
            statusText = disabledText;
            textColor = "black";
          }
          const rowIntensity =
            row.status === -1 ? "-" : Math.round(row.intensity / 10);
          const isItemSelected =
            isSelected(row.id) || isSelectedOffline(row.id);
          const labelId = `enhanced-table-checkbox-${index}`;

          const intervalModeLabel =
            intervalSet !== "0000000000000000"
              ? calcIntervalModeLabel(intervalIntensity, row.roomSize)
              : "";
          return (
            <TableRow
              hover
              role="checkbox"
              aria-checked={isItemSelected}
              tabIndex={-1}
              key={row.id}
              selected={isItemSelected}
            >
              <TableCell padding="checkbox">
                <Checkbox
                  onClick={() => handleClick(row.id, row.status)}
                  checked={isItemSelected}
                  inputProps={{ "aria-labelledby": labelId }}
                  // disabled={row.status === -1}
                />
              </TableCell>
              <TableCell component="th" id={labelId} scope="row" padding="none">
                {`${row.name}`}
              </TableCell>
              {/* <TableCell component="th" id={labelId} scope="row" padding="none">
                {`${row.id}`}
              </TableCell> */}
              <TableCell align="left">
                {row.status === -1 ? "-" : row.scent}
              </TableCell>
              <TableCell align="left" style={{ color: textColor }}>
                {statusText}
              </TableCell>
              <TableCell align="left">
                {intervalSet !== "0000000000000000" && row.status !== -1
                  ? `Interval Mode (${t(intervalModeLabel)})`
                  : rowIntensity}
              </TableCell>
              {/* <TableCell align="left">
                {intervalSet !== "0000000000000000" && row.status > 0
                  ? `On: ${timeOn}, Off: ${timeOff}, Repeats: ${repeats}`
                  : "-"}
              </TableCell> */}
              <TableCell align="right">
                {row.scent === "-" || row.status === -1
                  ? "-"
                  : `${Math.floor(row.remain)}%`}
              </TableCell>
              <TableCell align="right">
                {row.isSub && <CardMembershipIcon />}
              </TableCell>
              <TableCell align="right">
                <Button
                  color="primary"
                  variant="text"
                  disabled={loading}
                  onClick={() => {
                    if (row.status !== -1) {
                      setSelected(rows.filter((item) => item.id === row.id));
                    } else {
                      setSelectedOffline(
                        rows.filter((item) => item.id === row.id),
                      );
                    }
                    setShowDetails(true);
                  }}
                >
                  {t("common.details")}
                </Button>
              </TableCell>
            </TableRow>
          );
        })}
      {emptyRows > 0 && (
        <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
          <TableCell colSpan={6} />
        </TableRow>
      )}
    </TableBody>
  );
};

export default EnhancedTableBody;
