import { Box, IconButton, Tab } from "@mui/material";
import { DataGrid, GridCellParams, GridColDef } from "@mui/x-data-grid";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import QRCode from "react-qr-code";
import Popover from "@mui/material/Popover";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { DeviceListProps } from "../../@types/allTypes";
import {
  calcIntervalModeLabel,
  checkIntervalSet,
} from "../../config/services/funcs";

const SideList = ({
  rowsSelected,
  rowsDisabled,
  setIsLoading,
  noSelected,
}: {
  rowsSelected: DeviceListProps[];
  rowsDisabled: DeviceListProps[];
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  noSelected: boolean;
}) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [value, setValue] = useState("");
  const [tabValue, setTabValue] = useState(noSelected ? "2" : "1");
  const [filteredRows, setFilteredRows] = useState<DeviceListProps[]>([]);
  const [disabledFilteredRows, setDisabledFilteredRows] = useState<
    DeviceListProps[]
  >([]);
  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    const field = event.currentTarget.dataset.field!;
    const id = event.currentTarget.parentElement!.dataset.id!;
    let row = rowsSelected.find((r) => r.id === id)!;
    if (!row) {
      row = rowsDisabled.find((r) => r.id === id)!;
    }
    type ObjectKey = keyof typeof row;
    const fieldOfRow = field as ObjectKey;
    const rowValue = row[fieldOfRow];
    if (typeof rowValue === "string") {
      setValue(rowValue);
    } else {
      setValue("");
    }
    if (field !== "qrcode" || row[field].includes("undefined")) {
      setAnchorEl(null);
    } else {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setTabValue(newValue);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const remapRows = (rows: DeviceListProps[]) => {
    return rows.map((element) => {
      let statusText: string;
      if (element.status === 1) {
        statusText = t("common.activeText");
      } else if (element.status === 0) {
        statusText = t("common.inactiveText");
      } else {
        statusText = t("common.disabledText");
      }
      const newElement = element;
      if (newElement.scent !== "-") {
        if (typeof newElement.remain === "string") {
          newElement.remain = Math.floor(parseInt(newElement.remain, 10));
        } else {
          newElement.remain = Math.floor(newElement.remain);
        }
      } else {
        newElement.remain = "-";
      }
      newElement.qrcode = `1:${element.aferoData}:${element.id}`;
      newElement.statusText = statusText;
      const intervalSet =
        newElement.attributes.filter((attribute) => {
          return attribute.id === 16;
        })[0]?.data || "0000000000000000";
      const { intervalIntensity, timeOff, timeOn, repeats } =
        checkIntervalSet(intervalSet);
      if (typeof newElement.intensity === "string")
        newElement.intensity = parseInt(newElement.intensity, 10);
      newElement.intensity =
        intervalSet !== "0000000000000000"
          ? `Interval: ${t(
              calcIntervalModeLabel(intervalIntensity, newElement.roomSize),
            )}`
          : `${Math.floor(newElement.intensity / 10)}`;
      newElement.fw =
        element.attributes
          .find((idValue) => {
            return idValue.id === 3;
          })
          ?.value.replace("\u0000", "") || "";
      return newElement;
    });
  };

  useEffect(() => {
    const rowsRemaped = remapRows(rowsSelected);
    setFilteredRows(rowsRemaped);
  }, [rowsSelected]);

  useEffect(() => {
    const rowsRemaped = remapRows(rowsDisabled);
    setDisabledFilteredRows(rowsRemaped);
  }, [rowsDisabled]);

  const columns: GridColDef[] = [
    { field: "id", headerName: t("devices.tableHead8"), width: 150 },
    { field: "name", headerName: t("devices.tableHead1"), width: 120 },
    {
      field: "scent",
      headerName: t("devices.tableHead2"),
      width: 160,
      editable: false,
    },
    {
      field: "remain",
      headerName: t("devices.tableHead5"),
      renderCell(params) {
        return params.value >= 0 ? `${params.value}%` : params.value;
      },
      width: 100,
      editable: false,
    },
    {
      field: "intensity",
      headerName: t("devices.tableHead4"),
      width: 120,
      editable: false,
    },
    {
      field: "statusText",
      headerName: t("devices.tableHead3"),
      width: 80,
      editable: false,
    },
    {
      field: "fw",
      headerName: "Firmware",
      width: 90,
      editable: false,
    },
    {
      field: "qrcode",
      headerName: "QRCode",
      renderCell(params) {
        return params.value.includes("undefined") ? null : (
          <QRCode size={40} value={params.value} />
        );
      },
      width: 80,
      editable: false,
    },
  ];

  return (
    <Box sx={{ height: 400, width: "100%" }}>
      <TabContext value={tabValue}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList onChange={handleChange}>
            {!noSelected && <Tab label={t("common.selected")} value="1" />}

            <Tab label={t("common.disabledText")} value="2" />
          </TabList>
        </Box>
        <TabPanel value="1" sx={{ padding: "12px" }}>
          <DataGrid
            rows={filteredRows}
            columns={columns}
            disableColumnFilter
            disableColumnMenu
            disableColumnSelector
            disableDensitySelector
            slotProps={{
              cell: {
                onClick: handlePopoverOpen,
              },
            }}
            getCellClassName={(params: GridCellParams<any, any, number>) => {
              if (params.field === "statusText") {
                if (params.value === t("common.activeText")) {
                  return "activeSideList";
                }
                if (params.value === t("common.inactiveText")) {
                  return "inactiveSideList";
                }
                return "disabledSideList";
              }
              return "";
            }}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 10,
                },
              },
            }}
            pageSizeOptions={[10]}
            disableRowSelectionOnClick
          />
        </TabPanel>
        <TabPanel value="2" sx={{ padding: "12px" }}>
          <DataGrid
            rows={disabledFilteredRows}
            columns={columns}
            disableColumnFilter
            disableColumnMenu
            disableColumnSelector
            disableDensitySelector
            slotProps={{
              cell: {
                onClick: handlePopoverOpen,
              },
            }}
            getCellClassName={(params: GridCellParams<any, any, number>) => {
              if (params.field === "status") {
                if (params.value === t("common.activeText")) {
                  return "activeSideList";
                }
                if (params.value === t("common.inactiveText")) {
                  return "inactiveSideList";
                }
                return "disabledSideList";
              }
              return "";
            }}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 10,
                },
              },
            }}
            pageSizeOptions={[10]}
            disableRowSelectionOnClick
          />
        </TabPanel>
      </TabContext>
      <Popover
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <IconButton onClick={handlePopoverClose}>
          <QRCode size={150} value={value} />
        </IconButton>
      </Popover>
    </Box>
  );
};

export default SideList;
