import { Typography, Slider } from "@mui/material";
import { SyntheticEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { SliderProps } from "../../@types/allTypes";

const valuetext = (value: number) => {
  return `${value}ºC`;
};

const IntenseSlider = ({
  value: iValue,
  setIntensity,
  disabled,
  min,
  max,
}: SliderProps) => {
  const { t } = useTranslation();

  const marks = [
    {
      value: 0,
      label: t("common.low"),
    },
    {
      value: 1,
      label: "1",
    },
    {
      value: 2,
      label: "2",
    },
    {
      value: 3,
      label: "3",
    },
    {
      value: 4,
      label: "4",
    },
    {
      value: 5,
      label: "5",
    },
    {
      value: 6,
      label: "6",
    },
    {
      value: 7,
      label: "7",
    },
    {
      value: 8,
      label: "8",
    },
    {
      value: 9,
      label: "9",
    },
    {
      value: 10,
      label: t("common.high"),
    },
  ];

  const [currentValue, setCurrentValue] = useState<number>(
    Math.round(iValue / 10) || 0,
  );

  useEffect(() => {
    setCurrentValue(Math.round(iValue / 10) || 0);
  }, [iValue]);
  return (
    <div className="inteseSlider">
      <Typography id="discrete-slider" gutterBottom>
        {t("common.difIntensity")} ({currentValue})
      </Typography>
      <Slider
        value={currentValue}
        getAriaValueText={valuetext}
        aria-labelledby="discrete-slider"
        valueLabelDisplay="auto"
        step={1}
        marks={marks}
        min={min}
        max={max}
        disabled={disabled}
        onChange={(event: Event, value: number | number[]) => {
          if (!Array.isArray(value)) {
            setCurrentValue(value);
          }
        }}
        onChangeCommitted={(
          event: Event | SyntheticEvent<Element, Event>,
          value: number | number[],
        ) => {
          if (!Array.isArray(value)) {
            setIntensity(value);
          }
        }}
      />
    </div>
  );
};

export default IntenseSlider;
