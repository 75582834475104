import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import enTranslation from "./en/translation.json";
import deTranslation from "./de/translation.json";

export const resources = {
  en: {
    translation: enTranslation,
  },
  de: {
    translation: deTranslation,
  },
} as const;

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    fallbackLng: "en",
    resources,
    interpolation: {
      escapeValue: false, // React already does escaping
    },
  });

export const changeLang = (newLang: string) => {
  i18n.changeLanguage(newLang.toLowerCase());
};
