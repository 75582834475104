import { ReactElement, useState } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { doRefreshToken, isLogin } from "../../config/services/funcs";

interface Props {
  children: ReactElement;
}

const PrivateRoute = ({ children }: Props): ReactElement | null => {
  const location = useLocation();
  const [wait, setWait] = useState(false);
  const mustRefresh = async () => {
    await doRefreshToken();
  };
  const isAuthed = isLogin();
  if (isAuthed.action === "refresh" && wait === false) {
    setWait(true);
    setTimeout(async () => {
      await mustRefresh();
      setWait(false);
    }, 1);
  }
  if (wait) {
    return null;
  }
  return isAuthed.logged ? (
    children
  ) : (
    <Navigate to="/login" state={{ from: location }} />
  );
};

export default PrivateRoute;
