import { CssBaseline } from "@mui/material";
import { StyledEngineProvider, ThemeProvider } from "@mui/material/styles";
import { GlobalStyles } from "tss-react";
import React from "react";
import ReactDOM from "react-dom";
import { main } from "./config/themes/main/main";
import "./Locales/i18n";
import { App } from "./Pages/App";

ReactDOM.render(
  <StyledEngineProvider injectFirst>
    <ThemeProvider theme={main}>
      <CssBaseline />
      <GlobalStyles
        styles={{
          ":root": {
            "--spacing": main.spacing(1),
            "--spacing2": main.spacing(2),
            "--primaryMain": main.palette.primary.main,
            "--primaryLight": main.palette.primary.light,
            "--primaryDark": main.palette.primary.dark,
            "--secondaryMain": main.palette.secondary.main,
            "--secondaryLight": main.palette.secondary.light,
            "--secondaryDark": main.palette.primary.dark,
          },
        }}
      />
      <App />
    </ThemeProvider>
  </StyledEngineProvider>,
  document.getElementById("root"),
);
