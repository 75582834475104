import NationaleDemiBold from "../../../Fonts/Nationale-DemiBold.otf";
import NationaleRegular from "../../../Fonts/Nationale-Regular.otf";

const nationaleDemiBold = {
  fontFamily: "Nationale",
  fontStyle: "demi-bold",
  fontDisplay: "swap",
  fontWeight: "600",
  src: `
 local('NationaleDemiBold'),
 local('Nationale-DemiBold'),
 url(${NationaleDemiBold}) format('otf')
`,
  unicodeRange:
    "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF",
};

const nationaleRegular = {
  fontFamily: "Nationale",
  fontStyle: "regular",
  fontDisplay: "swap",
  fontWeight: "600",
  src: `
 local('NationaleRegular'),
 local('Nationale-Regular'),
 url(${NationaleRegular}) format('otf')
`,
  unicodeRange:
    "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF",
};

export const typography = {
  fontFamily: ["Nationale"].join(","),
  overrides: {
    MuiCssBaseline: {
      "@global": {
        "@font-face": [nationaleDemiBold, nationaleRegular],
      },
    },
  },
};
