import {
  createTheme,
  responsiveFontSizes,
  adaptV4Theme,
  Theme,
} from "@mui/material/styles";
import { palette } from "./palette";
import { typography } from "./typography";

declare module "@mui/material/styles" {
  type DefaultTheme = Theme;
}

const settings = {
  palette,
  typography,
};

export { palette, settings };
const theme = createTheme(adaptV4Theme(settings));
export const main = responsiveFontSizes(theme);
