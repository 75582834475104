import { Grid } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { LangArray } from "../../@types/allTypes";
import { changeLang } from "../../Locales/i18n";
import CustomButton from "../CustomButton/CustomButton";

const LangChange = ({ languages }: LangArray) => {
  const { i18n } = useTranslation();

  return (
    <>
      {languages.map((language: string) => {
        return (
          <Grid key={language} item xs={5} sm={3} md={2}>
            <CustomButton
              key={language}
              id={language}
              color={
                i18n.language.toLowerCase() === language.toLowerCase()
                  ? "secondary"
                  : "primary"
              }
              style={{ minWidth: "30px" }}
              onClick={(e: React.MouseEvent<HTMLElement>) =>
                changeLang((e.target as any).textContent)
              }
            >
              {language}
            </CustomButton>
          </Grid>
        );
      })}
    </>
  );
};

export default LangChange;
