/* eslint-disable react/destructuring-assignment */
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  Tooltip,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ChipData } from "../../@types/allTypes";

// Set days of the week
type DayType = {
  day: string;
  checked: boolean;
};

// Create your forceUpdate hook
const useForceUpdate = () => {
  const [value, setState] = useState(true);
  return () => setState(!value);
};

const SelectDays = (
  chosenDays: number[],
  chipData: ChipData[],
  setCurrentDays: React.Dispatch<React.SetStateAction<number[]>>,
  days: DayType[],
  setDays: React.Dispatch<React.SetStateAction<DayType[]>>,
) => {
  const { t } = useTranslation();
  const handleForceupdateMethod = useForceUpdate();
  const [currentChosenDays, setCurrentChosenDays] = useState<number[]>(
    chosenDays || [],
  );

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const currentDays = currentChosenDays;
    const isChecked = event.currentTarget.checked;
    const eventValue = parseInt(event.currentTarget.value, 10);
    const index = currentDays.findIndex((value) => value === eventValue);

    const newDays = days;

    if (isChecked) {
      newDays[eventValue] = { day: newDays[eventValue].day, checked: true };
      currentDays.push(eventValue);
    } else {
      newDays[eventValue] = { day: newDays[eventValue].day, checked: false };
      currentDays.splice(index, 1);
    }
    currentDays.sort();
    setDays(newDays);
    setCurrentChosenDays(currentDays);
    setCurrentDays(currentDays);
    handleForceupdateMethod();
  };

  const handleChangeAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    const currentDays: number[] = event.currentTarget.checked
      ? [0, 1, 2, 3, 4, 5, 6]
      : [];
    const newDays = days;
    days.forEach((element, index) => {
      newDays[index].checked = event.currentTarget.checked;
    });
    setDays(newDays);
    setCurrentChosenDays(currentDays);
    setCurrentDays(currentDays);
  };

  useEffect(() => {
    setCurrentChosenDays(chosenDays);
  }, [chosenDays]);

  return (
    <FormGroup>
      <Grid
        className="schedulerDays"
        container
        item
        justifyContent="flex-start"
        sx={{ textAlign: "start" }}
      >
        {days.map((day, index) => {
          const isUsed = chipData
            .map((chip) => chip.days.includes(index))
            .includes(true);
          return (
            <Grid key={day.day} item md={12}>
              <FormControlLabel
                key={day.day}
                value={index}
                labelPlacement="end"
                control={
                  <Checkbox
                    value={index}
                    checked={days[index].checked}
                    onChange={handleChange}
                  />
                }
                label={
                  <>
                    <Typography>{day.day}</Typography>
                    {isUsed ? (
                      <Tooltip title="Day in use" arrow>
                        <QuestionMarkIcon
                          sx={{ color: "red", height: "0.8em", width: "0.8em" }}
                        />
                      </Tooltip>
                    ) : null}
                  </>
                }
              />
            </Grid>
          );
        })}
      </Grid>
      <br />
      <Grid xs={12} container item justifyContent="flex-start">
        <FormControlLabel
          labelPlacement="end"
          control={
            <Checkbox
              indeterminate={
                currentChosenDays.length > 0 && currentChosenDays.length < 7
              }
              defaultChecked
              onChange={handleChangeAll}
            />
          }
          label={
            <Typography sx={{ fontSize: "0.8rem" }}>
              {t("scheduler.allDays")}
            </Typography>
          }
        />
      </Grid>
    </FormGroup>
  );
};
export default SelectDays;
