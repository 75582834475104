import { Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

const About = () => {
  const { t } = useTranslation();

  return (
    <Grid container className="about">
      <Grid item xs={12}>
        <Typography variant="h3" className="title">
          {t("about.title")}
        </Typography>
      </Grid>
      <Grid item xs={2} />
      <Grid item xs={8} className="text">
        <Typography variant="h6">{t("about.mainBody")}</Typography>
      </Grid>
    </Grid>
  );
};

export default About;
