import { Grid, Typography } from "@mui/material";
import { Trans, useTranslation } from "react-i18next";

const Faq = () => {
  const { t } = useTranslation();

  return (
    <Grid container justifyContent="center" className="faqPage">
      <Grid item xs={12} className="title">
        <Typography variant="h3">
          <Trans t={t} i18nKey="faq.title">
            FAQ$apos;s
          </Trans>
        </Typography>
      </Grid>
      <Grid
        item
        container
        justifyContent="center"
        alignItems="center"
        xs={12}
        sm={10}
        className="body"
        spacing={1}
      >
        <Grid item xs={10}>
          <Typography variant="h4" className="subtitle">
            <b>1-</b>{" "}
            <Trans t={t} i18nKey="faq.subTitle1">
              <b>INSTALLATION DEVICE</b>
            </Trans>
          </Typography>
          <Typography variant="h5">
            <Trans t={t} i18nKey="faq.subTitle1Caption1">
              <b>Can I install the smart device by myself?</b>
            </Trans>
          </Typography>
          <Typography variant="h6">
            <Trans t={t} i18nKey="faq.subTitle1Body1">
              Please check the legal requirements for electrical installations
              in the country of use. We recommend contacting an electrician in
              any case for installation.
            </Trans>
          </Typography>
          <Typography variant="h5">
            <Trans t={t} i18nKey="faq.subTitle1Caption2">
              <b>How to connect the smart device to the WLAN?</b>
            </Trans>
          </Typography>
          <Typography variant="h6">
            <Trans t={t} i18nKey="faq.subTitle1Body2">
              For the process of activating the installed device the revoltab
              mobile APP is required. The mobile app guides you step by step
              through the activation process and connection of the WLAN.
            </Trans>
          </Typography>
          <Typography variant="h5">
            <Trans t={t} i18nKey="faq.subTitle1Caption3">
              <b>Can I connect and steer the smart device by Bluetooth?</b>
            </Trans>
          </Typography>
          <Typography variant="h6">
            <Trans t={t} i18nKey="faq.subTitle1Body3">
              Yes. Bluetooth is available as well as WLAN connection.
            </Trans>
          </Typography>
          <Typography variant="h5">
            <Trans t={t} i18nKey="faq.subTitle1Caption4">
              <b>Does the smart device need a battery?</b>
            </Trans>
          </Typography>
          <Typography variant="h6">
            <Trans t={t} i18nKey="faq.subTitle1Body4">
              No. The smart device is directly connected to the power lines.
            </Trans>
          </Typography>
        </Grid>
      </Grid>

      <Grid
        item
        container
        justifyContent="center"
        alignItems="center"
        xs={12}
        sm={10}
        className="body"
        spacing={1}
      >
        <Grid item xs={10}>
          <Typography variant="h4" className="subtitle">
            <b>2-</b>{" "}
            <Trans t={t} i18nKey="faq.subTitle2">
              <b>DEVICE AND PRODUCT</b>
            </Trans>
          </Typography>

          <Typography variant="h5">
            <Trans t={t} i18nKey="faq.subTitle2Caption1">
              <b>What colors are available for the smart device? </b>
            </Trans>
          </Typography>
          <Typography variant="h6">
            <Trans t={t} i18nKey="faq.subTitle2Body1">
              The smart device is available in the colors white and black
            </Trans>
          </Typography>
          <Typography variant="h5">
            <Trans t={t} i18nKey="faq.subTitle2Caption2">
              <b>What room fragrance front colors are available?</b>
            </Trans>
          </Typography>
          <Typography variant="h6">
            <Trans t={t} i18nKey="faq.subTitle2Body2">
              The tab fronts are available in white and black.
            </Trans>
          </Typography>
          <Typography variant="h5">
            <Trans t={t} i18nKey="faq.subTitle2Caption3">
              <b>What room size can one installed smart device cover? </b>
            </Trans>
          </Typography>
          <Typography variant="h6">
            <Trans t={t} i18nKey="faq.subTitle2Body3">
              The smart device coverage of about 50 m2. If larger coverage is
              required another installation is recommended.
            </Trans>
          </Typography>
        </Grid>
      </Grid>

      <Grid
        item
        container
        justifyContent="center"
        alignItems="center"
        xs={12}
        sm={10}
        className="body"
        spacing={1}
      >
        <Grid item xs={10}>
          <Typography variant="h4" className="subtitle">
            <b>3-</b>{" "}
            <Trans t={t} i18nKey="faq.subTitle3">
              <b>MOBILE AND DASHBOARD APP</b>
            </Trans>
          </Typography>
          <Typography variant="h5">
            <Trans t={t} i18nKey="faq.subTitle3Caption1">
              <b>Does the mobile APP work with iOS and Android? </b>
            </Trans>
          </Typography>
          <Typography variant="h6">
            <Trans t={t} i18nKey="faq.subTitle3Body1">
              The revoltab mobile APP is available for Android (Google Play
              Store) and iOS (Apple Store)
            </Trans>
          </Typography>
          <Typography variant="h5">
            <Trans t={t} i18nKey="faq.subTitle3Caption2">
              <b>Where can I download the mobile APP?</b>
            </Trans>
          </Typography>
          <Typography variant="h6">
            <Trans t={t} i18nKey="faq.subTitle3Body2">
              Android (Google Play Store) and iOS (Apple Store)
            </Trans>
          </Typography>
          <Typography variant="h5">
            <Trans t={t} i18nKey="faq.subTitle3Caption3">
              <b>Is the transmitted usage data-protected? </b>
            </Trans>
          </Typography>
          <Typography variant="h6">
            <Trans t={t} i18nKey="faq.subTitle3Body3">
              Yes. The transmitted data is 100% protected by the newest
              technology. Please also check the revoltab ‘Privacy Policy’.
            </Trans>
          </Typography>
          <Typography variant="h5">
            <Trans t={t} i18nKey="faq.subTitle3Caption4">
              <b>How can I log in to the personalized revoltab dashboard? </b>
            </Trans>
          </Typography>
          <Typography variant="h6">
            <Trans t={t} i18nKey="faq.subTitle3Body4">
              Open the browser, go to www.revoltab.com and sign in if you have
              an existing account. If no account is available please register
              and set up a new account.
            </Trans>
          </Typography>
        </Grid>
      </Grid>

      <Grid
        item
        container
        justifyContent="center"
        alignItems="center"
        xs={12}
        sm={10}
        className="body"
        spacing={1}
      >
        <Grid item xs={10}>
          <Typography variant="h4" className="subtitle">
            <b>4-</b>{" "}
            <Trans t={t} i18nKey="faq.subTitle4">
              <b>SCENT TAB AND ROOM FRAGRANCES</b>
            </Trans>
          </Typography>
          <Typography variant="h5">
            <Trans t={t} i18nKey="faq.subTitle4Caption1">
              <b>
                What is the room fragrance tab’s lifetime and how do I know when
                it is empty?{" "}
              </b>
            </Trans>
          </Typography>
          <Typography variant="h6">
            <Trans t={t} i18nKey="faq.subTitle4Body1">
              The tab contains room fragrance for a maximal running time of 500
              hours. The mobile app or dashboard app shows the time remaining
              and informs you in advance when reordering is necessary. If the
              room fragrance tab is empty the smart device is automatically
              switched off.
            </Trans>
          </Typography>
          <Typography variant="h5">
            <Trans t={t} i18nKey="faq.subTitle4Caption2">
              <b>How do I know when the revoltab tab needs to be replaced?</b>
            </Trans>
          </Typography>
          <Typography variant="h6">
            <Trans t={t} i18nKey="faq.subTitle4Body2">
              The mobile or dashboard app will push this information and inform
              when action is required.
            </Trans>
          </Typography>
          <Typography variant="h5">
            <Trans t={t} i18nKey="faq.subTitle4Caption3">
              <b>Are room fragrance tabs recyclable? </b>
            </Trans>
          </Typography>
          <Typography variant="h6">
            <Trans t={t} i18nKey="faq.subTitle4Body3">
              The revoltab system is fully sustainable and the used room
              fragrance tabs can be recycled. The used room fragrance tabs just
              need to be sent back and revoltab takes care of it.
            </Trans>
          </Typography>
          <Typography variant="h5">
            <Trans t={t} i18nKey="faq.subTitle4Caption4">
              <b>Does revoltab use essential oils?</b>
            </Trans>
          </Typography>
          <Typography variant="h6">
            <Trans t={t} i18nKey="faq.subTitle4Body4">
              Revoltab offers a wide range of premium ingredients. We comply
              with the highest market standards and more. All our scent products
              comply with the highest IFRA standards and more. All our scent
              compositions contain essential oils.
            </Trans>
          </Typography>
        </Grid>
      </Grid>

      <Grid
        item
        container
        justifyContent="center"
        alignItems="center"
        xs={12}
        sm={10}
        className="body"
        spacing={1}
      >
        <Grid item xs={10}>
          <Typography variant="h4" className="subtitle">
            <b>5-</b>{" "}
            <Trans t={t} i18nKey="faq.subTitle5">
              <b>PURCHASING MODELS</b>
            </Trans>
          </Typography>
          <Typography variant="h5">
            <Trans t={t} i18nKey="faq.subTitle5Caption1">
              <b>How can I sign up for an ‘as a service’ model?</b>
            </Trans>
          </Typography>
          <Typography variant="h6">
            <Trans t={t} i18nKey="faq.subTitle5Body1">
              Choose the ‘flat rate’ or ‘pay per hour’ model when choosing the
              room fragrance tab at{" "}
              <a
                href="https://www.revoltab.com/shop_tab"
                target="_blank"
                rel="noreferrer"
              >
                www.revoltab.com/shop_tab.
              </a>{" "}
              Please check the terms and conditions.
            </Trans>
          </Typography>
          <Typography variant="h5">
            <Trans t={t} i18nKey="faq.subTitle5Caption2">
              <b>
                How does the flat rate for ‘as a service’ purchasing model work?
              </b>
            </Trans>
          </Typography>
          <Typography variant="h6">
            <Trans t={t} i18nKey="faq.subTitle5Body2">
              The flat rate for ‘as a service’ model covers the needs of heavy
              users. The user receives as many room fragrance tabs per smart
              device as needed for a monthly flat fee paid by credit card.
            </Trans>
          </Typography>
          <Typography variant="h5">
            <Trans t={t} i18nKey="faq.subTitle5Caption3">
              <b>
                Is there a monthly base fee per hour for ‘as a service’ models?{" "}
              </b>
            </Trans>
          </Typography>
          <Typography variant="h6">
            <Trans t={t} i18nKey="faq.subTitle5Body3">
              Yes. The ‘pay per hour’ purchase model requires a monthly base fee
              for the offered service. The subscription time corresponds to a
              complete room fragrance tab. For example, with an operating time
              of four months till the tab is empty, the ‘pay per hour’
              subscription ends at the same time.
            </Trans>
          </Typography>
          <Typography variant="h6">
            <Trans t={t} i18nKey="faq.subTitle5Body4">
              Please check the rate{" "}
              <a
                href="https://www.revoltab.com/shop_tab"
                target="_blank"
                rel="noreferrer"
              >
                www.revoltab.com/shop_tab.
              </a>{" "}
              The monthly base fee and the per-hour usage will be charged
              monthly by credit card.
            </Trans>
          </Typography>
          <Typography variant="h5">
            <Trans t={t} i18nKey="faq.subTitle5Caption4">
              <b>
                Are the room fragrance tabs automatically delivered when using
                the ‘as a service’ models?
              </b>
            </Trans>
          </Typography>
          <Typography variant="h6">
            <Trans t={t} i18nKey="faq.subTitle5Body5">
              revoltab offers automatic delivery if the ‘flat rate’ or ‘pay per
              hour’ as a service model is chosen.
            </Trans>
          </Typography>
        </Grid>
      </Grid>

      <Grid
        item
        container
        justifyContent="center"
        alignItems="center"
        xs={12}
        sm={10}
        className="body"
        spacing={1}
      >
        <Grid item xs={10}>
          <Typography variant="h4" className="subtitle">
            <b>6-</b>{" "}
            <Trans t={t} i18nKey="faq.subTitle6">
              <b>SHIPPING</b>
            </Trans>
          </Typography>
          <Typography variant="h5">
            <Trans t={t} i18nKey="faq.subTitle6Caption1">
              <b>Where do you ship to? </b>
            </Trans>
          </Typography>
          <Typography variant="h6">
            <Trans t={t} i18nKey="faq.subTitle6Body1">
              We ship to Switzerland. More regions are coming soon.
            </Trans>
          </Typography>
          <Typography variant="h5">
            <Trans t={t} i18nKey="faq.subTitle6Caption2">
              <b>Do you charge for shipping? </b>
            </Trans>
          </Typography>
          <Typography variant="h6">
            <ol>
              <Trans t={t} i18nKey="faq.subTitle6Body2">
                <li>
                  Single tab purchase and device / Shipping free from CHF 100.
                </li>
                <li> ‘as a service purchase models / Shipping free</li>
              </Trans>
            </ol>
          </Typography>
          <Typography variant="h5">
            <Trans t={t} i18nKey="faq.subTitle6Caption3">
              <b>Can I track the delivery of my order? </b>
            </Trans>
          </Typography>
          <Typography variant="h6">
            <Trans t={t} i18nKey="faq.subTitle6Body3">
              Yes, the tracking number will be forwarded to the registered
              e-mail address as soon as the delivery is on its way.
            </Trans>
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Faq;
