import { Grid, Typography } from "@mui/material";
import { Trans, useTranslation } from "react-i18next";

const Help = () => {
  const { t } = useTranslation();

  return (
    <Grid container justifyContent="center" className="helpPage">
      <Grid item xs={12} className="title">
        <Typography variant="h3">
          <Trans t={t} i18nKey="help.title">
            Dashboard (Help)
          </Trans>
        </Typography>
      </Grid>
      <Grid
        item
        container
        justifyContent="center"
        alignItems="center"
        xs={12}
        sm={10}
        className="body"
        spacing={2}
      >
        <Grid item xs={10}>
          <Typography variant="h6">
            <Trans t={t} i18nKey="help.mainBody">
              The Revoltab Dashboard allows the controlling and managing of one
              or multiple devices convenient in house or remote from everywhere
              in the world.
            </Trans>
          </Typography>
        </Grid>
        <Grid item xs={10}>
          <Typography variant="h5">
            <Trans t={t} i18nKey="help.subTitle1">
              <b>What functions are available:</b>
            </Trans>
          </Typography>
          <Typography variant="h6">
            <ol>
              <Trans t={t} i18nKey="help.subBody1">
                <li>
                  Setting and overview of operating schedule (hour, day, week)
                </li>
                <li>Setting, controlling of the intensity level</li>
                <li>
                  Oversee the devices to be maintained / order requirement
                </li>
                <li>
                  Direct link to revoltab webshop for ordering fragrance tabs
                </li>
                <li>
                  Overview of automated ordering process if a “as a service”
                  purchasing model is chosen.
                </li>
              </Trans>
            </ol>
          </Typography>
        </Grid>
        <Grid item xs={10}>
          <Typography variant="h5">
            <Trans t={t} i18nKey="help.subTitle2">
              <b> Interface to PMS and booking systems</b>
            </Trans>
          </Typography>
        </Grid>
        <Grid item xs={10}>
          <Typography variant="h6">
            <Trans t={t} i18nKey="help.subBody2">
              Link the revoltab system directly to the PMS or booking system in
              use (interface). This enables you to optimize scheduling planning
              aligned with your bookings without any effort.
            </Trans>
          </Typography>
        </Grid>
        <Grid item xs={10}>
          <Typography variant="h5">
            <Trans t={t} i18nKey="help.contactBody">
              <b>Contact us for more information.</b>
            </Trans>
          </Typography>
          <Typography variant="h6">
            <a href="mailto:contact@revoltab.com">contact@revoltab.com</a>
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Help;
