import { Button, Grid, Paper, TextField } from "@mui/material";
import { useState } from "react";

import { FormTypes } from "../../@types/allTypes";

type FormProps = {
  maxWidth: string;
  field1: string;
  field1Type: string;
  field2: string;
  field2Type: string;
  buttonText: string;
  buttonFunc: (
    arg0: FormTypes,
    arg1: React.Dispatch<React.SetStateAction<boolean>>,
  ) => void;
};

const Form = ({
  maxWidth,
  field1,
  field1Type,
  field2,
  field2Type,
  buttonText,
  buttonFunc,
}: FormProps) => {
  const [fieldValues, setFieldValues] = useState<FormTypes>({
    value1: "",
    value2: "",
  });
  const [loggingIn, setLoggingIn] = useState<boolean>(false);

  return (
    <form
      noValidate
      onSubmit={(e) => {
        e.preventDefault();
      }}
      className="formMolecule"
      style={{ maxWidth }}
    >
      <Paper elevation={2}>
        <Grid
          container
          alignItems="center"
          justifyContent="space-evenly"
          style={{ padding: "15px 0" }}
          spacing={2}
        >
          <Grid item xs={12}>
            <TextField
              label={field1}
              variant="outlined"
              type={field1Type}
              id="field1"
              name="field1"
              onChange={(e) =>
                setFieldValues((oldValues) => ({
                  ...oldValues,
                  value1: e.target.value,
                }))
              }
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label={field2}
              variant="outlined"
              type={field2Type}
              id="field2"
              name="field2"
              onChange={(e) =>
                setFieldValues((oldValues) => ({
                  ...oldValues,
                  value2: e.target.value,
                }))
              }
            />
          </Grid>
          <Grid item xs={6}>
            <Button
              disabled={loggingIn}
              onClick={() => {
                setLoggingIn(true);
                buttonFunc(fieldValues, setLoggingIn);
              }}
              type="submit"
              variant="contained"
            >
              {buttonText}
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </form>
  );
};

export default Form;
