import { Toolbar, Typography } from "@mui/material";
import clsx from "clsx";
import { useTranslation } from "react-i18next";

type EnhancedTableToolbarType = {
  numSelected: number;
};

const EnhancedTableToolbar = ({ numSelected }: EnhancedTableToolbarType) => {
  const { t } = useTranslation();

  return (
    <Toolbar className={clsx("toolBar root")}>
      {numSelected > 0 ? (
        <Typography
          className="title"
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} {`${t("common.devices")} ${t("common.selected")}`}
        </Typography>
      ) : null}
      {/* 
            {numSelected > 0 ? (
                <Tooltip title="Delete">
                    <IconButton aria-label="delete">
                        <Delete />
                    </IconButton>
                </Tooltip>
            ) : (
                <Tooltip title="Filter list">
                    <IconButton aria-label="filter list">
                        <FilterList />
                    </IconButton>
                </Tooltip>
            )} */}
    </Toolbar>
  );
};

export default EnhancedTableToolbar;
