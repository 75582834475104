/* eslint-disable no-await-in-loop */
import { CSSProperties, useEffect, useState } from "react";
import { Grid, Paper, Typography } from "@mui/material";
import { useCSVReader } from "react-papaparse";
import { toast } from "react-toastify";
import CustomButton from "../../Molecules/CustomButton/CustomButton";
import { LangChange } from "../../Molecules/LangChange";
import { doRefreshToken, isLogin, logout } from "../../config/services/funcs";
import {
  sendHistoryDevices,
  sendTabData,
  updateRevoltabShop,
} from "../../config/services/requestService";
import { HistoryDevicesType, TabImportType } from "../../@types/allTypes";

const Header = () => {
  const languages: Array<string> = ["DE", "EN"];
  const [wait, setWait] = useState(false);
  const [inUse, setInUse] = useState(false);
  const { CSVReader } = useCSVReader();

  const logged = isLogin();

  useEffect(() => {
    const refresh = async () => {
      setWait(true);
      await doRefreshToken();
      setWait(false);
    };
    if (logged.action === "refresh" && wait === false) {
      refresh();
    }
  }, [logged.action]);

  if (wait) {
    return <div>Loading...</div>;
  }

  const aferoUploadFunc = async (results: HistoryDevicesType) => {
    if (logged.accessToken) {
      let i = 0;
      let newDevicesAdded = 0;
      let totalDevicesList = 0;
      let updatedDevices = 0;
      let isSuccess = true;
      while (i < results.data.length) {
        console.log(
          `Iteration: ${i}, Remaining data: ${results.data.length - i}`,
        );
        const aferoSplit = results.data.slice(i, i + 30);
        try {
          const response = await sendHistoryDevices(
            aferoSplit,
            logged.accessToken,
          );
          newDevicesAdded += response.data.newDevices;
          totalDevicesList = response.data.totalDevices;
          updatedDevices = response.data.updatedDevices;
        } catch (error) {
          isSuccess = false;
          toast.error("Error ocurred");
        }
        i += 30;
      }
      console.log(
        `Finished iterating, total devices: ${totalDevicesList}, new devices: ${newDevicesAdded}, Updated Devices: ${updatedDevices}`,
      );
      if (isSuccess) {
        toast.success(
          `Total Devices: ${totalDevicesList}, New Devices: ${newDevicesAdded}, Updated Devices: ${updatedDevices}`,
        );
      }
    }
  };

  const tabUploadFunc = async (results: TabImportType) => {
    if (logged.accessToken) {
      let i = 0;
      let newTabsAdded = 0;
      let totalTabs = 0;
      let isSuccess = true;
      while (i < results.data.length) {
        console.log(
          `Iteration: ${i}, Remaining data: ${results.data.length - i}`,
        );
        const tabSplit = results.data.slice(i, i + 30);
        try {
          const response = await sendTabData(tabSplit, logged.accessToken);
          newTabsAdded += response.data.newDevices;
          totalTabs = response.data.totalDevices;
        } catch (error) {
          isSuccess = false;
          toast.error("Error ocurred");
        }
        i += 30;
      }
      console.log(
        `Finished iterating, total Tabs: ${totalTabs}, new Tabs: ${newTabsAdded}`,
      );
      if (isSuccess) {
        toast.success(`Total Tabs: ${totalTabs}, New Tabs: ${newTabsAdded}`);
      }
    }
  };

  const headerFunc = (header: string) => {
    const result = header.replaceAll(" ", "_");
    return result;
  };

  const styles = {
    csvReader: {
      display: "flex",
      flexDirection: "row",
      marginBottom: 10,
    } as CSSProperties,
    browseFile: {
      width: "20%",
    } as CSSProperties,
    acceptedFile: {
      border: "1px solid #ccc",
      height: 45,
      lineHeight: 2.5,
      paddingLeft: 10,
      width: "80%",
    } as CSSProperties,
    remove: {
      borderRadius: 0,
      padding: "0 20px",
    } as CSSProperties,
    progressBarBackgroundColor: {
      backgroundColor: "red",
    } as CSSProperties,
  };

  return (
    <Paper>
      <Grid
        className="headerGrid"
        container
        justifyContent="space-between"
        alignContent="center"
      >
        <Grid item xs={4}>
          <a href="/">
            <img className="image" alt="Logo" src="/logo.png" />
          </a>
        </Grid>
        <Grid item xs={2}>
          {logged.isAdmin && (
            <>
              <Typography color="white">Import Device File</Typography>
              <CSVReader
                config={{
                  header: true,
                  transformHeader: (header: string) => headerFunc(header),
                }}
                onUploadAccepted={async (results: HistoryDevicesType) => {
                  await aferoUploadFunc(results);
                }}
              >
                {({
                  getRootProps,
                  acceptedFile,
                  ProgressBar,
                  getRemoveFileProps,
                }: any) => (
                  <>
                    <div style={styles.csvReader}>
                      <button
                        type="button"
                        {...getRootProps()}
                        style={styles.browseFile}
                      >
                        Browse file
                      </button>
                      <div style={styles.acceptedFile}>
                        {acceptedFile && acceptedFile.name}
                      </div>
                      <button
                        type="submit"
                        {...getRemoveFileProps()}
                        style={styles.remove}
                      >
                        Remove
                      </button>
                    </div>
                    <ProgressBar style={styles.progressBarBackgroundColor} />
                  </>
                )}
              </CSVReader>
            </>
          )}
        </Grid>
        <Grid item xs={2}>
          {logged.isAdmin && (
            <>
              <Typography color="white">Import Tabs</Typography>
              <CSVReader
                config={{
                  header: true,
                }}
                onUploadAccepted={async (results: TabImportType) => {
                  await tabUploadFunc(results);
                }}
              >
                {({
                  getRootProps,
                  acceptedFile,
                  ProgressBar,
                  getRemoveFileProps,
                }: any) => (
                  <>
                    <div style={styles.csvReader}>
                      <button
                        type="button"
                        {...getRootProps()}
                        style={styles.browseFile}
                      >
                        Browse file
                      </button>
                      <div style={styles.acceptedFile}>
                        {acceptedFile && acceptedFile.name}
                      </div>
                      <button
                        type="submit"
                        {...getRemoveFileProps()}
                        style={styles.remove}
                      >
                        Remove
                      </button>
                    </div>
                    <ProgressBar style={styles.progressBarBackgroundColor} />
                  </>
                )}
              </CSVReader>
            </>
          )}
        </Grid>
        <Grid item xs={1}>
          {logged.isAdmin && (
            <button
              type="submit"
              disabled={inUse}
              onClick={async () => {
                setInUse(true);
                const response = await updateRevoltabShop();
                if (response.status === 200) {
                  setInUse(false);
                  const customer: { updated: number } = response.data;
                  toast.success(`Shop Customers Updated: ${customer.updated}`);
                } else {
                  setInUse(false);
                  toast.error(`Error updating shop customers`);
                }
              }}
            >
              Update Shop Customers
            </button>
          )}
        </Grid>
        <Grid
          item
          container
          xs={3}
          alignContent="center"
          justifyContent="center"
        >
          <Grid item container xs={6} className="langLog">
            {logged.logged && (
              <CustomButton
                color="primary"
                onClick={() => {
                  logout();
                }}
              >
                <a href="/login">Logout</a>
              </CustomButton>
            )}
            <LangChange languages={languages} />
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default Header;
